import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useForm, Form } from "../common/useForm";
import Controls from "../common/FormType/Controls";
import { PdfDocument } from "./QuotationSalesDocs";
import { PDFDownloadLink } from "@react-pdf/renderer";

const initialValues = {
  customerStore: "",
  kioskAmount: "1",
  kioskPrice: "15000000",
  adminRoomkey: "5",
  customerRoomkey: "45",
  monthlyFee: "100000",
  kioskAddItemAmount: "",
  kioskAddItemPrice: "",
  kioskAddItemAmountTwo: "",
  kioskAddItemPriceTwo: "",
  kioskAddItemAmountThree: "",
  kioskAddItemPriceThree: "",
  kioskAddItemAmountFour: "",
  kioskAddItemPriceFour: "",
  kioskAddItemAmountFive: "",
  kioskAddItemPriceFive: "",
  kioskType: "키오스크&클라우드 객실관리 프로그램",
  kioskTypeEA: "EA(Permanent)",
  kioskRemark: "하드웨어 2년 무상보증",
  serviceName: "S/W 무료 업데이트 & 신기능 업그레이드",
  serviceEA: "Services",
  monthlyFeeRemark: "월별 청구",
  quotationDate: new Date().toISOString(),
};

export default function QuotationSalesForm(props) {
  const { recordForEdit, user, setQuotationNumPrice, salesCallback } = props;
  const [show, setHide] = useState(false);
  const [showAddItem, setShowItem] = useState(false);
  const [showAddItemTwo, setShowItemTwo] = useState(false);
  const [showAddItemThree, setShowItemThree] = useState(false);
  const [showAddItemFour, setShowItemFour] = useState(false);
  const [showAddItemFive, setShowItemFive] = useState(false);
  const [count, setCount] = useState(0);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const addItem = () => {
    setCount((count) => count + 1);
    if (count == 0) {
      setShowItem(true);
    } else if (count == 1) {
      setShowItemTwo(true);
    } else if (count == 2) {
      setShowItemThree(true);
    } else if (count == 3) {
      setShowItemFour(true);
    } else if (count == 4) {
      setShowItemFive(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setHide(true);
    setQuotationNumPrice({
      kioskAmount: values.kioskAmount,
      kioskPrice: values.kioskPrice,
    });
  };

  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ...values,
        ...recordForEdit,
        ...user,
      });
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container alignContent="center">
        <Grid item xs={12}>
          <h3>키오스크 판매형</h3>
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            variant="outlined"
            label="업체명"
            name="customerStore"
            placeholder="Placeholder"
            value={values.customerStore}
            onChange={handleInputChange}
          />
          <Controls.Input
            variant="outlined"
            label="키오스크 수량"
            type="number"
            name="kioskAmount"
            value={values.kioskAmount}
            onChange={handleInputChange}
          />
          <Controls.Input
            variant="outlined"
            label="키오스크 가격"
            name="kioskPrice"
            type="number"
            value={values.kioskPrice}
            onChange={handleInputChange}
          />
          <Controls.Input
            id="outlined-multiline-static"
            label="기타사항"
            name="kioskAddMemo"
            multiline
            rows={2}
            variant="outlined"
            type="number"
            value={values.kioskAddMemo}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            variant="outlined"
            label="월 서비스 요금"
            name="monthlyFee"
            type="number"
            value={values.monthlyFee}
            onChange={handleInputChange}
          />
          <Controls.Input
            variant="outlined"
            label="관리자 객실키"
            name="adminRoomkey"
            type="number"
            value={values.adminRoomkey}
            onChange={handleInputChange}
          />
          <Controls.Input
            variant="outlined"
            label="사용자 객실키"
            name="customerRoomkey"
            type="number"
            value={values.customerRoomkey}
            onChange={handleInputChange}
          />
          <Controls.DatePicker
            name="quotationDate"
            label="날짜"
            value={values.quotationDate}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <h3>품목 추가</h3>
        </Grid>
        {showAddItem && (
          <>
            <Grid item xs={2}>
              <Controls.Input
                variant="outlined"
                label="품목1"
                name="kioskAddItem"
                value={values.kioskAddItem}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Controls.Input
                variant="outlined"
                label="규격1"
                name="kioskAddItemDetail"
                value={values.kioskAddItemDetail}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Controls.Input
                variant="outlined"
                label="수량1"
                name="kioskAddItemAmount"
                type="number"
                value={values.kioskAddItemAmount}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Controls.Input
                variant="outlined"
                label="단가1"
                name="kioskAddItemPrice"
                type="number"
                value={values.kioskAddItemPrice}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3}>
              <Controls.Input
                variant="outlined"
                label="비고1"
                name="kioskAddItemRemarks"
                value={values.kioskAddItemRemarks}
                onChange={handleInputChange}
              />
            </Grid>
          </>
        )}
        {showAddItemTwo && (
          <>
            <Grid item xs={2}>
              <Controls.Input
                variant="outlined"
                label="품목2"
                name="kioskAddItemTwo"
                value={values.kioskAddItemTwo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Controls.Input
                variant="outlined"
                label="규격2"
                name="kioskAddItemDetailTwo"
                value={values.kioskAddItemDetailTwo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Controls.Input
                variant="outlined"
                label="수량2"
                name="kioskAddItemAmountTwo"
                type="number"
                value={values.kioskAddItemAmountTwo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Controls.Input
                variant="outlined"
                label="단가2"
                name="kioskAddItemPriceTwo"
                type="number"
                value={values.kioskAddItemPriceTwo}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3}>
              <Controls.Input
                variant="outlined"
                label="비고2"
                name="kioskAddItemRemarksTwo"
                value={values.kioskAddItemRemarksTwo}
                onChange={handleInputChange}
              />
            </Grid>
          </>
        )}
        {showAddItemThree && (
          <>
            <Grid item xs={2}>
              <Controls.Input
                variant="outlined"
                label="품목3"
                name="kioskAddItemThree"
                value={values.kioskAddItemThree}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Controls.Input
                variant="outlined"
                label="규격3"
                name="kioskAddItemDetailThree"
                value={values.kioskAddItemDetailThree}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Controls.Input
                variant="outlined"
                label="수량3"
                name="kioskAddItemAmountThree"
                type="number"
                value={values.kioskAddItemAmountThree}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Controls.Input
                variant="outlined"
                label="단가3"
                name="kioskAddItemPriceThree"
                type="number"
                value={values.kioskAddItemPriceThree}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3}>
              <Controls.Input
                variant="outlined"
                label="비고3"
                name="kioskAddItemRemarksThree"
                value={values.kioskAddItemRemarksThree}
                onChange={handleInputChange}
              />
            </Grid>
          </>
        )}
        {showAddItemFour && (
          <>
            <Grid item xs={2}>
              <Controls.Input
                variant="outlined"
                label="품목4"
                name="kioskAddItemFour"
                value={values.kioskAddItemFour}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Controls.Input
                variant="outlined"
                label="규격4"
                name="kioskAddItemDetailFour"
                value={values.kioskAddItemDetailFour}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Controls.Input
                variant="outlined"
                label="수량4"
                name="kioskAddItemAmountFour"
                type="number"
                value={values.kioskAddItemAmountFour}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Controls.Input
                variant="outlined"
                label="단가4"
                name="kioskAddItemPriceFour"
                type="number"
                value={values.kioskAddItemPriceFour}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3}>
              <Controls.Input
                variant="outlined"
                label="비고4"
                name="kioskAddItemRemarksFour"
                value={values.kioskAddItemRemarksFour}
                onChange={handleInputChange}
              />
            </Grid>
          </>
        )}
        {showAddItemFive && (
          <>
            <Grid item xs={2}>
              <Controls.Input
                variant="outlined"
                label="품목5"
                name="kioskAddItemFive"
                value={values.kioskAddItemFive}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Controls.Input
                variant="outlined"
                label="규격5"
                name="kioskAddItemDetailFive"
                value={values.kioskAddItemDetailFive}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Controls.Input
                variant="outlined"
                label="수량5"
                name="kioskAddItemAmountFive"
                type="number"
                value={values.kioskAddItemAmountFive}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Controls.Input
                variant="outlined"
                label="단가5"
                name="kioskAddItemPriceFive"
                type="number"
                value={values.kioskAddItemPriceFive}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3}>
              <Controls.Input
                variant="outlined"
                label="비고5"
                name="kioskAddItemRemarksFive"
                value={values.kioskAddItemRemarksFive}
                onChange={handleInputChange}
              />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <Controls.Button text="품목 추가" onClick={addItem} />
          {salesCallback && (
            <Controls.Button
              text="품목 복사"
              onClick={() => salesCallback(values)}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <div style={{ display: "flex" }}>
            <Controls.Button type="submit" text="견적서생성" />
            {show && (
              <PDFDownloadLink
                document={<PdfDocument data={values} />}
                fileName={`벤디트견적서(판매형)_${values.customerStore}.pdf`}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                  marginLeft: "5px",
                  textDecoration: "none",
                  padding: "10px",
                  color: "#4a4a4a",
                  backgroundColor: "#f2f2f2",
                  border: "1px solid #4a4a4a",
                }}
              >
                {({ blob, url, loading, error }) =>
                  loading ? "로딩중" : "PDF 다운로드"
                }
              </PDFDownloadLink>
            )}
          </div>
        </Grid>
      </Grid>
    </Form>
  );
}
