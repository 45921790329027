import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { FaFileContract, FaFileAlt, FaMailBulk } from "react-icons/fa";
import PageHeader from "../../components/PageHeader/PageHeader";
import RegisterForm from "../../components/Customer/RegisterForm";
import ContractForm from "../../components/Customer/ContractForm";
import useTable from "../../components/common/useTable";
import * as customerService from "../../lib/api/FormService/CustomerService";
import Controls from "../../components/common/FormType/Controls";
import Popup from "../../components/common/Popup";
import ChatBotForm from "../../components/Customer/ChatBotForm";
import QuotationForm from "../../components/Customer/QuotationForm";
import MemoForm from "../../components/Customer/MemoForm";
import QuotationDetailsTable from "../../components/Customer/QuotationDetailsTable";
import ContractDetailsTable from "../../components/Customer/ContractDetailsTable";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "40%",
  },
  newButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  tblCell: {
    textAlign: "center",
  },
}));

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* align children centered vertically */
  align-items: center; /* align children centered horizontally */
`;

const BottomLine = styled.div`
  /* border-bottom: 1px solid rgba(224, 224, 224, 1); */
  border-bottom: ${(props) =>
    props.isWebOpen ? "1px solid rgba(224, 224, 224, 1);" : null};
`;

const CustomerMain = () => {
  const { user } = useSelector(({ user }) => ({ user: user.user }));

  const headCells = [
    { id: "customerRegister", label: "등록날짜", userauthority: 1 },
    {
      id: "customerStore",
      label: "업체명",
      disableSorting: true,
      userauthority: 1,
    },
    { id: "customerName", label: "고객명", userauthority: 1 },
    {
      id: "customerPhone",
      label: "휴대폰",
      disableSorting: true,
      userauthority: 1,
    },
    {
      id: "customerEmail",
      label: "이메일",
      disableSorting: true,
      userauthority: 1,
    },
    { id: "location", label: "위치", disableSorting: true, userauthority: 1 },
    {
      id: "customerIncome",
      label: "유입경로",
      disableSorting: true,
      userauthority: 1,
    },
    {
      id: "customerKeyTech",
      label: "키텍/객실수/OTA",
      disableSorting: true,
      userauthority: 1,
    },
    {
      id: "Quotation",
      label: "견적서&계약서",
      disableSorting: true,
      userauthority: user?.userauthority > 0 ? 1 : 0,
    },
    {
      id: "customerMemo",
      label: "상담메모",
      disableSorting: true,
      userauthority: 1,
    },
    {
      id: "customerStatus",
      label: "진행상태",
      disableSorting: true,
      userauthority: 1,
    },
  ];

  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [memoOpenPopup, setMemoOpenPopup] = useState(false);
  const [contractOpenPopup, setContractOpenPopup] = useState(false);
  const [quotationOpenPopup, setQuotationOpenPopup] = useState(false);
  const [quotationDetailsOpenPopup, setQuotationDetailsOpenPopup] =
    useState(false);
  const [contractDetailsOpenPopup, setContractDetailsOpenPopup] =
    useState(false);
  const [chatBOTOpenPopup, setChatBOTOpenPopup] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await customerService.getAllEmployees();
        // const result = response.map((item) => {
        //   let filteredStatus;

        //   if (item.customerStatus === null) {
        //     filteredStatus = "문의";
        //   } else if (item.customerStatus === 1) {
        //     filteredStatus = "견적서 발송";
        //   } else if (item.customerStatus === 2) {
        //     filteredStatus = "계약서 발송";
        //   } else if (item.customerStatus === 3) {
        //     filteredStatus = "구매완료(구독형)";
        //   } else if (item.customerStatus === 4) {
        //     filteredStatus = "구매완료(판매형)";
        //   }
        //   return {...item, customerStatus: filteredStatus}
        // })
        setRecords(response);
        // setRecords();
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);

  const handleSearch = (e) => {
    const {
      target: { value },
    } = e;
    return value;
  };

  const handleKeyPress = (e) => {
    const value = handleSearch(e);
    if (e.key === "Enter") {
      setFilterFn({
        fn: (items) => {
          if (value == "") return items;
          return items.filter(
            (x) =>
              x.createdAt?.toLowerCase().includes(value) ||
              x.customerName?.toLowerCase().includes(value) ||
              x.customerPhone?.toLowerCase().includes(value) ||
              x.customerPhoneTwo?.toLowerCase().includes(value) ||
              x.customerEmail?.toLowerCase().includes(value) ||
              x.customerEmailTwo?.toLowerCase().includes(value) ||
              x.customerStore?.toLowerCase().includes(value) ||
              x.customerKeyTech?.toLowerCase().includes(value) ||
              x.customerStatus?.toLowerCase().includes(value)
          );
        },
      });
    }
  };

  const addOrEdit = async (employee, resetForm) => {
    if (employee.id == 0) {
      await customerService.insertEmployee(employee);
    } else {
      await customerService.updateEmployee(employee);
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setRecords(await customerService.getAllEmployees());
    setNotify({
      isOpen: true,
      message: "제출 완료",
      type: "success",
    });
  };

  const chatBotadd = async (employee, resetForm) => {
    await customerService.insertEmployee(employee);
    resetForm();
    setChatBOTOpenPopup(false);
    setRecords(await customerService.getAllEmployees());
    setNotify({
      isOpen: true,
      message: "제출 완료",
      type: "success",
    });
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const contractOpenInPopup = (item) => {
    setRecordForEdit({
      id: item.id,
      customerEmail: item.customerEmail,
      customerPhone: item.customerPhone,
      customerStatus: item.customerStatus,
      customerStore: item.customerStore,
      customerName: item.customerName,
    });
    setContractOpenPopup(true);
  };

  const quotationOpenInPopup = (item) => {
    setRecordForEdit(item);
    setQuotationOpenPopup(true);
  };

  const memoOpenInPopup = (item) => {
    setRecordForEdit({ id: item.id, customerMemo: item.customerMemo });
    setMemoOpenPopup(true);
  };

  const quotationDetailsOpenInPopup = (item) => {
    setRecordForEdit({ id: item.id });
    setQuotationDetailsOpenPopup(true);
  };

  const contractDetailsOpenInPopup = (item) => {
    setRecordForEdit({ id: item.id });
    setContractDetailsOpenPopup(true);
  };

  // const onDelete = async (id) => {
  //   setConfirmDialog({
  //     ...confirmDialog,
  //     isOpen: false,
  //   });
  //   await customerService.deleteEmployee(id);
  //   setRecords(await customerService.getAllEmployees());
  //   setNotify({
  //     isOpen: true,
  //     message: '고객 삭제 완료',
  //     type: 'error',
  //   });
  // };

  return (
    <Wrapper>
      <PageHeader
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
        title="벤디트 고객 관리"
        subTitle="고객 관리 및 등록"
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="고객 검색"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
            onKeyPress={handleKeyPress}
          />
          <div
            style={{
              width: "60%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Controls.Button
              text="고객 등록"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            />
            {/* <Controls.Button
              text="챗봇 고객 등록"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setChatBOTOpenPopup(true);
              }}
            /> */}
          </div>
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {records &&
              recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell
                    className={classes.tblCell}
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    {item.createdAt.slice(2, 10)}
                    <div style={{ fontSize: "10px" }}>
                      {item.user !== null
                        ? `작성:${item.user.username}`
                        : "작성:채널톡"}
                    </div>
                  </TableCell>
                  <TableCell
                    className={classes.tblCell}
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    {item.customerStore}
                  </TableCell>
                  <TableCell
                    className={classes.tblCell}
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    {item.customerName}
                  </TableCell>
                  <TableCell
                    className={classes.tblCell}
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    <BottomLine isWebOpen={item.customerPhone}>
                      {item.customerPhone}
                    </BottomLine>
                    <BottomLine isWebOpen={item.customerPhoneTwo}>
                      {item.customerPhoneTwo}
                    </BottomLine>
                  </TableCell>
                  <TableCell
                    className={classes.tblCell}
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    <BottomLine isWebOpen={item.customerEmail}>
                      {item.customerEmail}
                    </BottomLine>
                    <BottomLine isWebOpen={item.customerEmailTwo}>
                      {item.customerEmailTwo}
                    </BottomLine>
                  </TableCell>
                  <TableCell
                    className={classes.tblCell}
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    {item.location}
                  </TableCell>
                  <TableCell
                    className={classes.tblCell}
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    {item.customerIncome === "1"
                      ? "전화"
                      : item.customerIncome === "2"
                      ? "홈페이지"
                      : item.customerIncome === "3"
                      ? "블로그"
                      : item.customerIncome === "4"
                      ? "카페"
                      : item.customerIncome === "5"
                      ? "지인소개"
                      : null}
                  </TableCell>
                  <TableCell
                    className={classes.tblCell}
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    <BottomLine isWebOpen={item.customerKeyTech}>
                      {item.customerKeyTech}
                    </BottomLine>
                    <BottomLine isWebOpen={item.customerStoreRoom}>
                      {item.customerStoreRoom && `${item.customerStoreRoom} 룸`}
                    </BottomLine>
                    <BottomLine isWebOpen={item.customerOTA}>
                      {item.customerOTA}
                    </BottomLine>
                  </TableCell>
                  {user?.userauthority > 0 && (
                    <TableCell className={classes.tblCell}>
                      {/* 견적서 */}
                      <div>
                        <Controls.ActionButton
                          color="secondary"
                          onClick={() => {
                            quotationOpenInPopup(item);
                          }}
                        >
                          <FaMailBulk />
                        </Controls.ActionButton>
                        <Controls.ActionButton
                          color="secondary"
                          onClick={() => {
                            quotationDetailsOpenInPopup(item);
                          }}
                        >
                          <FaFileAlt />
                        </Controls.ActionButton>
                      </div>
                      <div>
                        <Controls.ActionButton
                          color="secondary"
                          onClick={() => {
                            contractOpenInPopup(item);
                          }}
                        >
                          <FaMailBulk />
                        </Controls.ActionButton>
                        <Controls.ActionButton
                          color="secondary"
                          onClick={() => {
                            contractDetailsOpenInPopup(item);
                          }}
                        >
                          <FaFileAlt />
                        </Controls.ActionButton>
                      </div>
                    </TableCell>
                  )}
                  {/* 메모 */}
                  <TableCell className={classes.tblCell}>
                    {item.customerMemo !== null && item.customerMemo !== "" ? (
                      <>
                        <Controls.ActionButton
                          color="secondary"
                          onClick={() => {
                            memoOpenInPopup(item);
                          }}
                        >
                          <EditOutlinedIcon fontSize="small" />
                        </Controls.ActionButton>
                        <div style={{ fontSize: "10px" }}>
                          {`${item.customerMemo.slice(0, 15)}...`}
                        </div>
                      </>
                    ) : (
                      <Controls.ActionButton
                        color="primary"
                        onClick={() => {
                          memoOpenInPopup(item);
                        }}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </Controls.ActionButton>
                    )}
                  </TableCell>
                  <TableCell className={classes.tblCell}>
                    {item.customerStatus === null
                      ? "문의"
                      : item.customerStatus}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
      <Popup
        title="고객 관리"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <RegisterForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
          setRecords={setRecords}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
      <Popup
        title="상담메모"
        openPopup={memoOpenPopup}
        setOpenPopup={setMemoOpenPopup}
      >
        <MemoForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
          setRecords={setRecords}
          setOpenPopup={setMemoOpenPopup}
        />
      </Popup>
      <Popup
        title="계약서 발송"
        openPopup={contractOpenPopup}
        setOpenPopup={setContractOpenPopup}
      >
        <ContractForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
      </Popup>
      <Popup
        title="챗봇 고객 등록"
        openPopup={chatBOTOpenPopup}
        setOpenPopup={setChatBOTOpenPopup}
      >
        <ChatBotForm addOrEdit={chatBotadd} />
      </Popup>
      <Popup
        title="견적서 생성&발송"
        openPopup={quotationOpenPopup}
        setOpenPopup={setQuotationOpenPopup}
      >
        <QuotationForm
          recordForEdit={recordForEdit}
          addOrEdit={chatBotadd}
          setOpenPopup={setQuotationOpenPopup}
          setRecords={setRecords}
          setOpenProfilePopup={setOpenPopup}
        />
      </Popup>
      <Popup
        title="고객 별 견적서 내역"
        openPopup={quotationDetailsOpenPopup}
        setOpenPopup={setQuotationDetailsOpenPopup}
      >
        <QuotationDetailsTable recordForEdit={recordForEdit} />
      </Popup>
      <Popup
        title="고객 별 계약서 내역"
        openPopup={contractDetailsOpenPopup}
        setOpenPopup={setContractDetailsOpenPopup}
      >
        <ContractDetailsTable recordForEdit={recordForEdit} />
      </Popup>
    </Wrapper>
  );
};

export default CustomerMain;
