import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useForm, Form } from "../common/useForm";
import Controls from "../common/FormType/Controls";
import * as customerService from "../../lib/api/FormService/CustomerService";
import Notification from "../common/Notification";
import ConfirmDialog from "../common/ConfirmDialog";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import path from "path";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function QuotationBizcardForm({ setOpenPopup, recordForEdit }) {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
  });

  const initialValues = {
    fileName: "",
    fileType: "",
    fileRealFile: "",
  };

  const [pdfViewer, setPdfviewer] = useState(false);
  const [imageViewer, setImageviewer] = useState(null);

  const [orderStore, setOrderStore] = useState([
    {
      id: 0,
      fileType: "제품소개서 발송",
    },
    {
      id: 1,
      fileType: "제안서 발송",
    },
    {
      id: 2,
      fileType: "Default 명함",
    },
  ]);

  useEffect(() => {
    let filetype;
    switch (recordForEdit.filetype) {
      case "제품소개서 발송":
        filetype = 1;
        break;
      case "제안서 발송":
        filetype = 2;
        break;
      case "Default 명함":
        filetype = 3;
        break;
      default:
        break;
    }
    setValues({
      ...values,
      fileName: recordForEdit.filename,
      fileType: filetype,
    });

    // pdf view 구현문
    // const initialSetting = async () => {
    //   const pdffile = await customerService.getQuotationfilepdf({
    //     filetype: recordForEdit.filetype,
    //     filename: recordForEdit.filename,
    //     filepath: recordForEdit.filepath,
    //   });
    //   console.log(pdffile);

    // };
    // initialSetting();
  }, []);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const fileHandler = (e) => {
    const [file] = e.target.files;

    setPdfviewer(file.type == "application/pdf" ? true : false);
    console.log(file);
    // if (file.type == "application/pdf") {
    //   setPdfviewer(true);
    // } else {
    //   let imageResult = new FileReader();
    //   imageResult.onload = function () {

    //   }
    //   console.log(imageResult);

    // setImageviewer(imageResult.reasult);
    //   setPdfviewer(true);
    // }
    setValues({
      ...values,
      fileRealFile: file,
    });
  };
  const setFileType = (values) => {
    switch (values) {
      case 1:
        return "제품소개서 발송";
      case 2:
        return "제안서 발송";
      case 3:
        return "Default 명함";
      default:
        return "none";
    }
  };

  const onSubmit = async () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    console.log(values.fileType);
    const fileType = setFileType(values.fileType);
    const formData = await new FormData();
    await formData.append("file", values.fileRealFile);
    await formData.append("filename", values.fileName);
    await formData.append("fileoriginalname", values.fileRealFile.name);
    await formData.append("filetype", fileType);
    // add에 같은 기능이 있어서 재사용
    const result = await customerService.addQuotationfiles(formData);
    if (result === "success") {
      setNotify({
        isOpen: true,
        message: "편집 완료",
        type: "success",
      });
      setTimeout(() => {
        setOpenPopup(false);
      }, 1000);
    } else {
      setNotify({
        isOpen: true,
        message: "편집실패",
        type: "error",
      });
    }
  };

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleOrderDocsInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <div className="container">
      <Form encType="multipart/form-data">
        <Grid container alignContent="center">
          <Grid item xs={9}>
            {pdfViewer ? (
              <Grid justifyContent="center">
                <Document
                  file={values.fileRealFile}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} />
                </Document>
                <Grid container justifyContent="center" xs={11}>
                  <span
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                    }}
                    onClick={() =>
                      pageNumber > 1 ? setPageNumber(pageNumber - 1) : null
                    }
                  >
                    <ArrowBackIcon
                      style={{
                        cursor: "pointer",
                        marginRight: "10px",
                      }}
                    />
                  </span>
                  <span>
                    {pageNumber} / {numPages}
                  </span>
                  <span
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                    }}
                    onClick={() =>
                      pageNumber < numPages
                        ? setPageNumber(pageNumber + 1)
                        : null
                    }
                  >
                    <ArrowForwardIcon />
                  </span>
                </Grid>
              </Grid>
            ) : (
              <Grid justifyContent="center">
                <img src={values.fileRealFile} alt="" />
              </Grid>
            )}
          </Grid>
          <Grid item xs={3}>
            <Controls.Input
              variant="outlined"
              label="파일명"
              type="text"
              name="fileName"
              value={values.fileName}
              onChange={handleInputChange}
            />
            <Controls.Input
              type="file"
              name="fileRealFile"
              onChange={fileHandler}
              files
            />
            <Notification notify={notify} setNotify={setNotify} />
            <Grid container justifyContent="flex-end" xs={10}>
              <Controls.Button
                text="편집"
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: "편집 하시겠습니까?",
                    onConfirm: () => {
                      onSubmit();
                    },
                  });
                }}
              />
            </Grid>
            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
