import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Axios from "axios";
import { useSelector } from "react-redux";
import { useForm, Form } from "../common/useForm";
import Controls from "../common/FormType/Controls";
import * as customerService from "../../lib/api/FormService/CustomerService";

const initialValues = {
  // id: 0,
  // customerName: '',
  // customerPhone: '',
  // customerEmail: '',
  contractName: "",
  commonExp: "10",
  commonMessage: "",
  departmentId: "",
  // gender: 'male',
  hireDate: new Date().toISOString(),
  // isPermanent: false,
};

export default function ContractForm(props) {
  const history = useHistory();
  const { addOrEdit, recordForEdit } = props;
  const [totalTemplate, setTotalTemplate] = useState([]);
  const { user } = useSelector(({ user }) => ({ user: user.user }));

  const getDepartmentCollection = (data) => {
    const testtemp = data.map((el) => {
      return { id: el.id, title: el.title };
    });
    return testtemp;
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
        ...initialValues,
        // ...user,
      });
  }, [recordForEdit]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(
          "https://api.glosign.com/v1/template/list?from=20200120&to=20211230",
          {
            headers: {
              Authorization: "Bearer lxCL35e2L6SgEO3dvDpd1idW1",
            },
          }
        );
        setTotalTemplate(response.data.templateList);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const ttest = await totalTemplate.filter(
      (el) => el.id == values.departmentId
    );

    const fetchData = async () => {
      try {
        let receiverOption;

        if (ttest[0]?.receiver_count === 1) {
          receiverOption = {
            receiverList: [
              {
                signOrderNumber: 1,
                name: values.customerName,
                email: values.customerEmail,
                userPhone: values.customerPhone,
                userPhoneCode: "KR/+82",
                lang: "kr",
                expired_day: parseInt(values.commonExp),
                identityAuth: "string",
                message: values.commonMessage,
              },
            ],
          };
        } else {
          receiverOption = {
            receiverList: [
              {
                signOrderNumber: 1,
                name: "벤디트",
                email: "ad@vendit.co.kr",
                userPhone: "01043712929",
                userPhoneCode: "KR/+82",
                lang: "kr",
                expired_day: parseInt(values.commonExp),
              },
              {
                signOrderNumber: 2,
                name: values.customerName,
                email: values.customerEmail,
                userPhone: values.customerPhone,
                userPhoneCode: "KR/+82",
                lang: "kr",
                expired_day: parseInt(values.commonExp),
                identityAuth: "string",
              },
            ],
          };
        }

        const response = await Axios({
          url: "https://api.glosign.com/v1/template/send", // your url
          headers: { Authorization: "Bearer lxCL35e2L6SgEO3dvDpd1idW1" },
          method: "post",
          data: {
            templateId: values.departmentId,
            contractName: values.contractName,
            commonMessage: values.commonMessage,
            commonExp: parseInt(values.commonExp),
            lang: "ko",
            certLang: "ko",
            emailFlag: true,
            mobileFlag: true,
            clientId: "ue8969793f8cf51d8e8eef98b90fdd449",
            contractList: [
              {
                signOrder: true,
                isReview: false,
                contractName: values.contractName,
                preSigner: {
                  coord: [
                    {
                      id: "text_0",
                      data: values.hireDate.substring(0, 4),
                    },
                    {
                      id: "text_1",
                      data: values.hireDate.substring(5, 7),
                    },
                    {
                      id: "text_2",
                      data: values.hireDate.substring(8, 10),
                    },
                  ],
                },
                ...receiverOption,
              },
            ],
          },
        });

        await customerService.insertContract({
          templateName: ttest[0]?.title,
          customerStore: values.customerStore,
          username: values.username,
          userId: user.id,
          customerInfoId: values.id,
          contractId: response.data.contractList[0].contractId,
        });
        await customerService.updateEmployee({
          id: values.id,
          customerStatus: "계약서발송",
        });
        history.push(`/contracts/${response.data.contractList[0].contractId}`);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container alignContent="center">
        <Grid item xs={6}>
          <Controls.Input
            variant="outlined"
            label="계약서명"
            name="contractName"
            value={values.contractName}
            onChange={handleInputChange}
            // error={errors.customerStore}
          />
          <Controls.Input
            variant="outlined"
            label="만료일"
            name="commonExp"
            value={values.commonExp}
            type="number"
            onChange={handleInputChange}
            // error={errors.location}
          />
          <Controls.Input
            variant="outlined"
            label="보낼메세지"
            name="commonMessage"
            value={values.commonMessage}
            onChange={handleInputChange}
            // error={errors.customerIncomePage}
          />
          <Controls.DatePicker
            name="hireDate"
            label="Hire Date"
            value={values.hireDate}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            variant="outlined"
            label="고객명"
            name="customerName"
            value={values.customerName}
            onChange={handleInputChange}
            // error={errors.customerName}
          />
          <Controls.Input
            variant="outlined"
            label="휴대폰"
            name="customerPhone"
            value={values.customerPhone}
            onChange={handleInputChange}
            // error={errors.customerPhone}
          />
          <Controls.Input
            variant="outlined"
            label="이메일"
            name="customerEmail"
            value={values.customerEmail}
            onChange={handleInputChange}
            // error={errors.customerEmail}
          />
          <Controls.Select
            name="departmentId"
            label="계약서 선택"
            value={values.departmentId}
            onChange={handleInputChange}
            options={totalTemplate && getDepartmentCollection(totalTemplate)}
          />
          <div>
            <Controls.Button type="submit" text="Submit" />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
}
