import React from "react";
import QutationAuthorityForm from "../Quotation/QutationAuthorityForm";
// import QutationAuthorityForm from "../Quotation/QutationBizcardForm";

export default function QuotationForm(props) {
  const { recordForEdit, setUploadUserAuthority } = props;

  return (
    <>
      <QutationAuthorityForm
        boardId={recordForEdit.id}
        setUploadUserAuthority={setUploadUserAuthority}
      />
    </>
  );
}
