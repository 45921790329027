import Axios from "axios";

const ORDER_CUSTOMER_URL = "/apis/orderingcompany";
export async function insertCompany(data) {
  try {
    const response = await Axios.post(ORDER_CUSTOMER_URL + "/register", data);
    return response.data === "denied" ? "denied" : "confirm";
  } catch (e) {
    console.log(e);
  }
}

export async function getAllCompany() {
  try {
    const data = await Axios.get(ORDER_CUSTOMER_URL + "/getcompany");
    return data.data;
  } catch (e) {
    console.error(e);
  }
}

export function updateCompany(data) {
  Axios.patch(ORDER_CUSTOMER_URL + "/edit", data)
    .then((res) => console.log(res.data))
    .catch((err) => console.log(err));
}

export async function deleteCompany(id) {
  try {
    const data = await Axios.delete(ORDER_CUSTOMER_URL + `/delete/${id}`);
  } catch (e) {
    console.error(e);
  }
}
