import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { useForm, Form } from "../common/useForm";
import Controls from "../common/FormType/Controls";
import { PdfDocument } from "./QuotationOtherDocs";
import { PDFDownloadLink } from "@react-pdf/renderer";
import * as orderingCompanyService from "../../lib/api/FormService/OrderingCompanyService";
import Notification from "../common/Notification";
import QuotationAddFormData from "./QuotationAddFormData";

const initialValues = {
  customerStore: "",
  orderSelectType: 0,
  quotationDate: new Date().toISOString(),
};

export default function QuotationOtherForm(props) {
  const { recordForEdit, user, setQuotationNumPrice } = props;
  const [show, setHide] = useState(false);

  const [itemData, setItemdata] = useState({
    itemName: "",
    itemUnit: "",
    itemQuantity: "",
    itemUnitPrice: "",
    itemOther: "",
  });
  const [itemArr, setItemarr] = useState([]);
  const [showItemSelect, setShowItemSelect] = useState(false);
  const [startComponent, setStartComponent] = useState(false);
  const [orderStore, setOrderStore] = useState([]);
  const [finalItemArr, setFinalItemArr] = useState([]);
  const [finalValues, setFinalValues] = useState(initialValues);
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);
  const AMOUNT = 16;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const addItem = () => {
    if (orderStore[values?.orderSelectType - 1] === undefined) {
      setNotify({
        isOpen: true,
        message: "거래처를 선택해주세요!!",
        type: "warning",
      });
      return;
    }

    setHide(false);
    setStartComponent(true);

    let copy;

    if (
      values?.orderItemSelectType === 0 ||
      values?.orderItemSelectType === undefined
    ) {
      copy = {
        item: "",
        salesprice: "",
        other: "",
        standard: "",
      };
    } else {
      copy =
        orderStore[values?.orderSelectType - 1].companyitem[
          values?.orderItemSelectType - 1
        ];
    }

    if (itemArr.length < AMOUNT) {
      setItemdata({
        itemName: copy.item,
        itemUnit: copy.standard,
        itemQuantity: 0,
        itemUnitPrice: copy.salesprice,
        itemOther: copy.other,
      });
    } else {
      setNotify({
        isOpen: true,
        message: "더 이상 추가하실 수 없습니다!!",
        type: "warning",
      });
    }
  };

  const onModifyArrays = useCallback(
    (localarray, localindex) => {
      const fixedArrya = itemArr.map((item, index) => {
        if (localindex === index) {
          return localarray;
        }
        return item;
      });
      setItemarr(fixedArrya);
    },
    [itemArr]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setHide(true);
    setQuotationNumPrice({
      kioskAmount: values.kioskAmount,
      kioskPrice: values.kioskPrice,
    });
  };

  const handleOrderDocsInputChange = (e) => {
    setShowItemSelect(true);
    const { name, value } = e.target;

    if (value === 0 && name === "orderSelectType") {
      setShowItemSelect(false);
      return;
    }

    setValues({
      ...values,
      [name]: value,
    });
  };

  const orderSelectLoop = () => {
    let flag;
    const loop = orderStore.map((item, index) => {
      for (let idx in item.companyitem) {
        flag = false;
        if (item.companyitem[idx].salesprice > 0) {
          flag = true;
          break;
        }
      }
      return flag ? { id: index + 1, title: item.companyName } : null;
    });
    const result = [{ id: 0, title: "거래처를 선택해 주세요" }, ...loop];
    return result;
  };

  const orderItemSelectLoop = () => {
    const newArray = [
      { item: "", price: "", salesprice: "1", standard: "" },
    ].concat(orderStore[values?.orderSelectType - 1]?.companyitem);
    const loop = newArray.map((item, index) => {
      return item.salesprice > 0
        ? { id: index, title: index == 0 ? "빈 칸 선택" : item.item }
        : null;
    });

    return loop;
  };

  // ======================= useEffect 절취선 =======================

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await orderingCompanyService.getAllCompany();
        let result = response.map((item) => {
          const result = {
            companyName: item.companyName,
            companyitem: JSON.parse(item.companyitem),
          };
          return result;
        });
        setOrderStore(result);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
    if (recordForEdit != null) {
      setValues({
        ...values,
        ...recordForEdit,
        ...user,
      });
    }
  }, []);

  useEffect(() => {
    if (startComponent) {
      setItemarr(itemArr.concat(itemData));
    }
  }, [itemData]);

  return (
    <Form onSubmit={handleSubmit}>
      <Notification notify={notify} setNotify={setNotify} />
      <Grid container alignContent="center">
        <Grid item xs={12}>
          <h3>기타 견적서</h3>
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            variant="outlined"
            label="업체명"
            name="customerStore"
            placeholder="Placeholder"
            value={values.customerStore}
            onChange={handleInputChange}
          />
          <Controls.Input
            id="outlined-multiline-static"
            label="기타사항"
            name="kioskAddMemo"
            multiline
            rows={2}
            variant="outlined"
            type="number"
            value={values.kioskAddMemo}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.DatePicker
            name="quotationDate"
            label="날짜"
            value={values.quotationDate}
            onChange={handleInputChange}
          />
          <Controls.ObjectSelect
            label="거래처선택"
            name="orderSelectType"
            value={values.orderSelectType}
            onChange={handleOrderDocsInputChange}
            options={orderSelectLoop()}
          />
          {showItemSelect ? (
            <Controls.ObjectSelect
              label="품목선택"
              name="orderItemSelectType"
              value={values.orderItemSelectType}
              onChange={handleOrderDocsInputChange}
              options={orderItemSelectLoop()}
            />
          ) : (
            ""
          )}
          <Grid item xs={10} container justifyContent="flex-end">
            <Controls.Button
              text={`품목 추가(최대${AMOUNT}개)`}
              onClick={addItem}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h3>품목 추가</h3>
        </Grid>
        {itemArr.map((data, index) => (
          <QuotationAddFormData
            values={data}
            index={index}
            arrays={itemArr}
            setValues={setItemarr}
            onModifyArrays={onModifyArrays}
          />
        ))}
        <Grid item xs={8} md={10}>
          <div style={{ display: "flex" }}>
            <Controls.Button
              type="submit"
              text="견적서생성"
              onClick={(e) => {
                setFinalItemArr(itemArr);
                setFinalValues(values);
              }}
            />
            {show && (
              <PDFDownloadLink
                document={
                  <PdfDocument data={finalItemArr} userValue={finalValues} />
                }
                fileName={`벤디트견적서(기타)_${values.customerStore}.pdf`}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                  marginLeft: "5px",
                  textDecoration: "none",
                  padding: "10px",
                  color: "#4a4a4a",
                  backgroundColor: "#f2f2f2",
                  border: "1px solid #4a4a4a",
                }}
              >
                {({ blob, url, loading, error }) =>
                  loading ? "로딩중" : "PDF 다운로드"
                }
              </PDFDownloadLink>
            )}
          </div>
        </Grid>
      </Grid>
    </Form>
  );
}
