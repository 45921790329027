import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Grid, Divider } from "@material-ui/core";
import { useForm, Form } from "../common/useForm";
import Controls from "../common/FormType/Controls";
import * as customerService from "../../lib/api/FormService/CustomerService";
import QuotationSalesForm from "../Quotation/QuotationSalesForm";
import QuotationSubForm from "../Quotation/QuotationSubForm";
import QuotationEmailForm from "../Quotation/QutationEmailForm";
import QuotationOrderForm from "../Quotation/QuotationOrderForm";
import QuotationOtherForm from "../Quotation/QuotationOtherForm";
import QutationInvoiceForm from "../Quotation/QutationInvoiceForm";

const initialValues = {
  quotationId: "",
  // departmentId: '',
  // gender: 'male',
  // hireDate: new Date().toISOString(),
  // isPermanent: false,
};

const getQuotationCollection = () => [
  { id: "1", title: "견적서 판매형" },
  { id: "2", title: "견적서 구독형" },
  { id: "3", title: "견적서(구독&판매)" },
  { id: "5", title: "견적서(기타)" },
  { id: "6", title: "인보이스(청구서)" },
  // { id: "4", title: "발주서" },
];

export default function QuotationForm(props) {
  const {
    addOrEdit,
    recordForEdit,
    setOpenPopup,
    setRecords,
    setOpenProfilePopup,
  } = props;
  const { user } = useSelector(({ user }) => ({ user: user.user }));
  const [salesValue, setSalesValue] = useState();

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const [quotationNumPrice, setQuotationNumPrice] = useState({
    kioskAmount: "",
    kioskPrice: "",
  });

  const salesCallback = useCallback((data) => setSalesValue(data), []);

  return (
    <>
      <div
        style={{
          width: "100%",
          marginTop: "10px",
        }}
      >
        <Form style={{ width: "100%" }}>
          <Grid container alignContent="center">
            <Grid item xs={6}>
              <Controls.Select
                name="quotationId"
                label="견적서 유형"
                value={values.quotationId}
                onChange={handleInputChange}
                options={getQuotationCollection()}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Button
                onClick={() => setOpenProfilePopup(true)}
                text="고객프로필"
              />
            </Grid>
          </Grid>
        </Form>
      </div>
      {values.quotationId == "1" && (
        <QuotationSalesForm
          recordForEdit={recordForEdit}
          setQuotationNumPrice={setQuotationNumPrice}
          user={user}
        />
      )}
      {values.quotationId == "2" && (
        <QuotationSubForm
          recordForEdit={recordForEdit}
          setQuotationNumPrice={setQuotationNumPrice}
          user={user}
        />
      )}
      {values.quotationId == "3" && (
        <>
          <QuotationSalesForm
            recordForEdit={recordForEdit}
            setQuotationNumPrice={setQuotationNumPrice}
            salesCallback={salesCallback}
            user={user}
          />
          <QuotationSubForm
            recordForEdit={recordForEdit}
            setQuotationNumPrice={setQuotationNumPrice}
            user={user}
            salesValue={salesValue}
          />
        </>
      )}
      {values.quotationId == "5" && (
        <QuotationOtherForm
          recordForEdit={recordForEdit}
          setQuotationNumPrice={setQuotationNumPrice}
          user={user}
        />
      )}
      {values.quotationId == "6" && (
        <QutationInvoiceForm
          recordForEdit={recordForEdit}
          setQuotationNumPrice={setQuotationNumPrice}
          user={user}
        />
      )}
      {/* {values.quotationId == "4" && (
        <QuotationOrderForm recordForEdit={recordForEdit} user={user} />
      )} */}
      <Divider />
      <h2>메일 발송</h2>
      <QuotationEmailForm
        estimate={false}
        recordForEdit={recordForEdit}
        quotationType={values.quotationId}
        setOpenPopup={setOpenPopup}
        setRecords={setRecords}
        quotationNumPrice={quotationNumPrice}
        user={user}
      />
    </>
  );
}
