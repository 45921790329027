import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Axios from "axios";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PageHeader from "../../components/PageHeader/PageHeader";
import useTable from "../../components/common/useTable";
import Controls from "../../components/common/FormType/Controls";
import Button from "../../components/common/Button";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* align children centered vertically */
  align-items: center; /* align children centered horizontally */
`;

const headCells = [
  { id: "templateTitle", label: "서식명", userauthority: 1 },
  { id: "signNumber", label: "서명자 수", userauthority: 1 },
  { id: "createdat", label: "생성일", disableSorting: true, userauthority: 1 },
  {
    id: "customerStore",
    label: "상세보기",
    disableSorting: true,
    userauthority: 1,
  },
];

const ContractTemplateMain = () => {
  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState([]);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(
          "https://api.glosign.com/v1/template/list?from=20200120&to=20211230",
          {
            headers: {
              Authorization: "Bearer lxCL35e2L6SgEO3dvDpd1idW1",
            },
          }
        );
        setRecords(response.data.templateList);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);

  const handleSearch = (e) => {
    const { target } = e;

    setFilterFn({
      fn: (items) => {
        if (target.value == "") return items;
        return items.filter((x) =>
          x.title?.toLowerCase().includes(target.value)
        );
      },
    });
  };

  return (
    <Wrapper>
      <PageHeader
        icon={<AssignmentIcon fontSize="large" />}
        title="벤디트 서식 관리"
        subTitle="계약서 서식관리 및 조회"
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="고객 검색"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {records &&
              recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.receiver_count}</TableCell>
                  <TableCell>{item.created_at.slice(0, 10)}</TableCell>
                  <TableCell>
                    <Button
                      to={`/contracttemplates/${item.id}`}
                      style={{ background: "crimson" }}
                    >
                      상세내역
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
    </Wrapper>
  );
};

export default ContractTemplateMain;
