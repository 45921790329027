import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { Form } from "../common/useForm";
import Controls from "../common/FormType/Controls";
import * as customerService from "../../lib/api/FormService/CustomerService";
import Notification from "../common/Notification";
import ConfirmDialog from "../common/ConfirmDialog";

export default function QutationAuthorityForm(props) {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
  });
  const { boardId, setUploadUserAuthority } = props;

  const onSubmit = async () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    const formData = new FormData();
    formData.append("id", boardId);
    const data = { id: boardId };
    const result = await customerService.updateUserAuthority(data);
    console.log(result);
    if (result.data === "success") {
      setNotify({
        isOpen: true,
        message: "부여 완료",
        type: "success",
      });
      setTimeout(() => {
        setUploadUserAuthority(false);
      }, 1000);
    } else {
      setNotify({
        isOpen: true,
        message: "부여 실패",
        type: "error",
      });
    }
  };
  return (
    <div className="container">
      <Form encType="multipart/form-data">
        <Grid container alignContent="center">
          <Notification notify={notify} setNotify={setNotify} />
          <Controls.Button
            text="권한부여"
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: "권한을 부여하시겠습니까?",
                onConfirm: () => {
                  onSubmit();
                },
              });
            }}
          />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </Grid>
      </Form>
    </div>
  );
}
