import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { useForm, Form } from "../common/useForm";
import Controls from "../common/FormType/Controls";
import Notification from "../common/Notification";
import ConfirmDialog from "../common/ConfirmDialog";
import * as customerService from "../../lib/api/FormService/CustomerService";
import Axios from "axios";
const customerIncome = [
  { id: "1", title: "전화" },
  { id: "2", title: "홈페이지" },
  { id: "3", title: "블로그" },
  { id: "4", title: "카페" },
  { id: "5", title: "지인소개" },
];

const customerStatus = [
  { id: "문의", title: "문의" },
  { id: "상담완료", title: "상담완료" },
  { id: "견적서발송", title: "견적서발송" },
  { id: "계약서발송", title: "계약서발송" },
  { id: "구매완료", title: "구매완료" },
];

const initialValues = {
  id: 0,
  customerName: "",
  customerPhone: "",
  customerPhoneTwo: "",
  customerEmail: "",
  customerEmailTwo: "",
  customerStore: "",
  location: "",
  customerCallPhone: "",
  customerStatus: "",
  customerMemo: "",
  customerKeyTech: "",
  customerStoreRoom: null,
  customerOTA: "",
  customerIncome: "1",
  userId: "",
  // customerIncomePage: '',
  // departmentId: '',
  // gender: 'male',
  // hireDate: new Date().toISOString(),
  // isPermanent: false,
};

export default function RegisterForm(props) {
  const { user } = useSelector(({ user }) => ({ user: user.user }));

  const { addOrEdit, recordForEdit, setRecords, setOpenPopup } = props;
  const [customerCallPhone, setCustomerCallPhone] = useState([]);
  const [customerCallPhoneInput, setCustomerCallPhoneInput] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const getCallCollection = (data) => {
    let totalCall = data.map((el) => {
      return { id: el.SRC, title: el.ENDTIME };
    });
    return totalCall;
  };
  // const validate = (fieldValues = values) => {
  //   const temp = { ...errors };
  //   if ('customerName' in fieldValues)
  //     temp.customerName = fieldValues.customerName
  //       ? ''
  //       : 'This field is required.';
  //   if ('customerEmail' in fieldValues)
  //     temp.customerEmail = /$^|.+@.+..+/.test(fieldValues.customerEmail)
  //       ? ''
  //       : 'Email is not valid.';
  //   if ('customerPhone' in fieldValues)
  //     temp.customerPhone =
  //       fieldValues.customerPhone.length > 9
  //         ? ''
  //         : 'Minimum 10 numbers required.';
  //   // if ('departmentId' in fieldValues)
  //   //   temp.departmentId =
  //   //     fieldValues.departmentId.length != 0 ? '' : 'This field is required.';
  //   setErrors({
  //     ...temp,
  //   });

  //   if (fieldValues == values) return Object.values(temp).every((x) => x == '');
  // };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.id === 0) {
      values.customerStatus = "문의";
      values.userId = user.id;
      addOrEdit(values, resetForm);
    } else {
      addOrEdit(values, resetForm);
    }
  };
  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  const getCustomerPhone = () => {
    const fetchData = async () => {
      try {
        const response = await customerService.getPhoneNumber();
        if (response) {
          // setValues({
          //   ...values,
          //   customerPhone: response?.data?.DATAS[0].SRC,
          // });
          setCustomerCallPhone(response.DATAS);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
    setCustomerCallPhoneInput(true);
  };
  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    await customerService.deleteEmployee(id);
    setRecords(await customerService.getAllEmployees());
    setNotify({
      isOpen: true,
      message: "고객 삭제 완료",
      type: "error",
    });
    setOpenPopup(false);
  };

  const handleCallInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      customerPhone: value,
    });
  };

  const handleCustomerStatusInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      customerStatus: value,
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container alignContent="center">
        <Grid item xs={6}>
          <Controls.Input
            variant="outlined"
            label="업체명"
            name="customerStore"
            value={values.customerStore}
            onChange={handleInputChange}
            // error={errors.customerStore}
          />
          <Controls.Input
            variant="outlined"
            label="고객명"
            name="customerName"
            value={values.customerName}
            onChange={handleInputChange}
            // error={errors.customerName}
          />
          <Controls.Input
            variant="outlined"
            label="휴대폰"
            name="customerPhone"
            value={values.customerPhone}
            onChange={handleInputChange}
            // error={errors.customerPhone}
          />
          <Controls.Input
            variant="outlined"
            label="휴대폰2"
            name="customerPhoneTwo"
            value={values.customerPhoneTwo}
            onChange={handleInputChange}
            // error={errors.customerPhone}
          />
          <Controls.Input
            variant="outlined"
            label="이메일"
            name="customerEmail"
            value={values.customerEmail}
            onChange={handleInputChange}
            // error={errors.customerEmail}
          />
          <Controls.Input
            variant="outlined"
            label="이메일2"
            name="customerEmailTwo"
            value={values.customerEmailTwo}
            onChange={handleInputChange}
            // error={errors.customerEmail}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            variant="outlined"
            label="위치"
            name="location"
            value={values.location}
            onChange={handleInputChange}
            // error={errors.location}
          />
          <Controls.Input
            variant="outlined"
            label="키텍 종류"
            name="customerKeyTech"
            value={values.customerKeyTech}
            onChange={handleInputChange}
            // error={errors.location}
          />
          <Controls.Input
            variant="outlined"
            label="객실수"
            type="number"
            name="customerStoreRoom"
            value={values.customerStoreRoom}
            onChange={handleInputChange}
            // error={errors.location}
          />
          <Controls.Input
            variant="outlined"
            label="OTA"
            name="customerOTA"
            value={values.customerOTA}
            onChange={handleInputChange}
            // error={errors.location}
          />
          <Controls.Select
            name="customerIncome"
            label="유입경로"
            value={values.customerIncome}
            onChange={handleInputChange}
            options={customerIncome}
          />
          {values.id !== 0 && (
            <Controls.Select
              name="customerStatus"
              label="진행상태"
              value={values.customerStatus}
              onChange={handleCustomerStatusInputChange}
              options={customerStatus}
            />
          )}

          {customerCallPhoneInput && customerCallPhone !== false && (
            <Controls.Select
              name="customerCallPhone"
              label="최근번호조회"
              value={values.customerCallPhone}
              onChange={handleCallInputChange}
              options={
                customerCallPhone && getCallCollection(customerCallPhone)
              }
            />
          )}
          <div>
            <Controls.Button type="submit" text="제출" />
            {values.id !== 0 && (
              <Controls.Button
                text="삭제"
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: "정말 삭제하시겠습니까??",
                    subTitle: "고객 삭제",
                    onConfirm: () => {
                      onDelete(values.id);
                    },
                  });
                }}
              />
            )}

            <Controls.Button text="최근번호조회" onClick={getCustomerPhone} />
          </div>
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </Grid>
      </Grid>
    </Form>
  );
}
