// import { combineReducers } from 'redux';
// import { all } from 'redux-saga/effects';
// import auth, { authSaga } from './auth';
// import loading from './loading';
// import user, { userSaga } from './user';

// const rootReducer = combineReducers({
//   auth,
//   loading,
//   user
// });

// export function* rootSaga() {
//   yield all([authSaga(), userSaga()]);
// }

// export default rootReducer;

// Ducks 패턴을 사용하여 액션타입, 액션 생성 함수, 리듀서가 하나의 파일에 다 정의됨
import { combineReducers } from 'redux';
import { all, takeLatest, call } from 'redux-saga/effects';
import auth, { authSaga, registerOnlySaga } from './auth';
import loading from './loading';
import user, { userSaga } from './user';

const rootReducer = combineReducers({
  auth,
  loading,
  user,
});

export function* rootSaga() {
  yield all([authSaga(), userSaga()]);
}

export default rootReducer;
