import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ContractDetail from "./pages/Contract/ContractDetail";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import EditorPage from "./pages/Users/EditorPage";
import ContractMain from "./pages/Contract/ContractMain";
import ContractTemplateMain from "./pages/ContractTemplate/ContractTemplateMain";
import Sidebar from "./components/sidebar/Sidebar";
import ContractTemplateDetail from "./pages/ContractTemplate/ContractTemplateDetail";
import CustomerMain from "./pages/Customer/CustomerMain";
import QuotationMain from "./pages/Quotation/QuotationMain";
import QuotationManagement from "./pages/Quotation/QuotationManagement";
import OrderingCompanyMain from "./pages/OrderingCompany/OrderingCompanyMain";
import DynamicRoute from "./lib/util/DynamicRoute";
import OtherFeaturesMain from "./pages/OtherFeatures/OtherFeaturesMain";

function App() {
  return (
    <Router>
      <Sidebar />
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <DynamicRoute path="/register" component={RegisterPage} authenticated />
        <DynamicRoute path="/edit" component={EditorPage} authenticated />
        <DynamicRoute path="/home" component={HomePage} authenticated />
        {/* 계약서 관리 */}
        <DynamicRoute path="/contract" component={ContractMain} authenticated />
        <DynamicRoute
          path="/contracts/:contractId"
          component={ContractDetail}
          authenticated
        />
        {/* 계약서 서식 및 발송 관리 */}
        <DynamicRoute
          path="/contracttemplate"
          component={ContractTemplateMain}
          authenticated
        />
        <DynamicRoute
          path="/contracttemplates/:id"
          component={ContractTemplateDetail}
        />
        {/* 고객 관리 */}
        <DynamicRoute
          path="/customers"
          component={CustomerMain}
          authenticated
        />
        <DynamicRoute
          path="/orderingcompany"
          component={OrderingCompanyMain}
          authenticated
        />
        {/* 견적서 */}
        <DynamicRoute
          path="/quotationmain"
          component={QuotationMain}
          authenticated
        />
        <DynamicRoute
          path="/quotationorder"
          component={QuotationMain}
          authenticated
        />
        <DynamicRoute
          path="/quotationmanagement"
          component={QuotationManagement}
          authenticated
        />
        {/* 기타기능 */}
        <DynamicRoute
          path="/otherfeatures"
          component={OtherFeaturesMain}
          authenticated
        />
      </Switch>
    </Router>
  );
}

export default App;
