import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { FaMailBulk } from "react-icons/fa";
import PageHeader from "../../components/PageHeader/PageHeader";
import useTable from "../../components/common/useTable";
import * as customerService from "../../lib/api/FormService/CustomerService";
import Controls from "../../components/common/FormType/Controls";
import Popup from "../../components/common/Popup";
import AddIcon from "@material-ui/icons/Add";
import QuotationEditFile from "../../components/Quotation/QuotationEditFile";
import QuotationAddFile from "../../components/Quotation/QuotationAddFile";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "40%",
  },
  newButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  tblCell: {
    textAlign: "center",
  },
}));

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* align children centered vertically */
  align-items: center; /* align children centered horizontally */
`;

const CustomerMain = () => {
  const { user } = useSelector(({ user }) => ({ user: user.user }));

  const headCells = [
    { id: "index", label: "순번", userauthority: 1 },
    { id: "filename", label: "파일 이름", userauthority: 1 },
    {
      id: "filetype",
      label: "파일 유형",
      disableSorting: true,
      userauthority: 1,
    },
    {
      id: "fileupdateAt",
      label: "등록 날짜",
      disableSorting: true,
      userauthority: 1,
    },
  ];

  const classes = useStyles();
  const [quotationEdit, setQuotationedit] = useState(false);
  const [quotationAddFile, setQuotationaddfile] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await customerService.getQuotationfiles();
        setRecords(response);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);

  const handleSearch = (e) => {
    const {
      target: { value },
    } = e;
    return value;
  };

  const handleKeyPress = (e) => {
    const value = handleSearch(e);
    if (e.key === "Enter") {
      setFilterFn({
        fn: (items) => {
          if (value == "") return items;
          return items.filter(
            (x) =>
              x.createdAt?.toLowerCase().includes(value) ||
              x.useremail?.toLowerCase().includes(value) ||
              x.username?.toLowerCase().includes(value) ||
              x.userphone?.toLowerCase().includes(value)
          );
        },
      });
    }
  };

  const QuotationEdit = (item) => {
    setRecordForEdit(item);
    setQuotationedit(true);
  };

  const QuotationAdd = () => {
    setQuotationaddfile(true);
  };

  return (
    <Wrapper>
      <PageHeader
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
        title="문서 파일 관리"
        subTitle="파일 관리 및 편집"
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="파일 검색"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
            onKeyPress={handleKeyPress}
          />
          <div
            style={{
              width: "60%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Controls.Button
              text="파일 등록"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                QuotationAdd();
              }}
            />
          </div>
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {records &&
              recordsAfterPagingAndSorting().map((item, index) => (
                <TableRow
                  key={item.id}
                  onClick={() => {
                    QuotationEdit(item);
                  }}
                >
                  <TableCell className={classes.tblCell}>{index + 1}</TableCell>
                  <TableCell className={classes.tblCell}>
                    {item.filename}
                  </TableCell>
                  <TableCell className={classes.tblCell}>
                    {item.filetype}
                  </TableCell>
                  <TableCell className={classes.tblCell}>
                    {item.updatedAt}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
      {/* <Popup
        title="파일 편집"
        openPopup={quotationEdit}
        setOpenPopup={setQuotationedit}
      >
        <QuotationEditFile
          recordForEdit={recordForEdit}
          setOpenPopup={setQuotationedit}
        />
      </Popup> */}
      <Popup
        title="파일 등록"
        openPopup={quotationAddFile}
        setOpenPopup={setQuotationaddfile}
      >
        <QuotationAddFile
          openPopup={quotationAddFile}
          setOpenPopup={setQuotationaddfile}
        />
      </Popup>
    </Wrapper>
  );
};

export default CustomerMain;
