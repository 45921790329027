import React from 'react'
import styled from "styled-components";
import PageHeader from '../../components/PageHeader/PageHeader';
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import OtherFeaturesSMS from '../../components/OtherFeatures/OtherFeaturesSMS';
import {
  Paper,
  makeStyles,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },

}));

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* align children centered vertically */
  align-items: center; /* align children centered horizontally */
`;


export default function OtherFeaturesMain() {
  const classes = useStyles();
  return (
    <Wrapper>
      <PageHeader
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
        title="문자발송 샘플"
        subTitle="문자발송 샘플"
      />
      <Paper className={classes.pageContent}>
        <OtherFeaturesSMS />
      </Paper>
    </Wrapper>
  )
}
