import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  Grid,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { FaFileContract, FaFileAlt, FaMailBulk } from "react-icons/fa";
import PageHeader from "../../components/PageHeader/PageHeader";

import ContractForm from "../../components/Customer/ContractForm";
import useTable from "../../components/common/useTable";
import * as orderingCompanyService from "../../lib/api/FormService/OrderingCompanyService";
import Controls from "../../components/common/FormType/Controls";
import Popup from "../../components/common/Popup";
import ChatBotForm from "../../components/Customer/ChatBotForm";
import MemoForm from "../../components/Customer/MemoForm";

import ContractDetailsTable from "../../components/Customer/ContractDetailsTable";
import RegisterForm from "../../components/OrderingCompnay/RegisterForm";
import QuotationForm from "../../components/Customer/QuotationForm";
import OrderDetailsTable from "../../components/OrderingCompnay/OrderDetailsTable";
import RegisterOrderItem from "../../components/OrderingCompnay/RegisterOrderItem";
import OrderQuotationForm from "../../components/OrderingCompnay/OrderQuotationForm";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "40%",
  },
  newButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  tblCell: {
    textAlign: "center",
  },
}));

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* align children centered vertically */
  align-items: center; /* align children centered horizontally */
`;

const BottomLine = styled.div`
  /* border-bottom: 1px solid rgba(224, 224, 224, 1); */
  border-bottom: ${(props) =>
    props.isWebOpen ? "1px solid rgba(224, 224, 224, 1);" : null};
`;

const OrderingCompanyMain = () => {
  const { user } = useSelector(({ user }) => ({ user: user.user }));

  const headCells = [
    { id: "columorder", label: "순서", disableSorting: true, userauthority: 1 },
    { id: "createdAt", label: "등록날짜", userauthority: 1 },
    {
      id: "companyRegisterNumber",
      label: "거래처명",
      userauthority: 1,
      disableSorting: true,
    },
    {
      id: "companyName",
      label: "사업자번호",
      disableSorting: true,
      userauthority: 1,
    },
    {
      id: "customeraccount",
      label: "은행명/계좌번호",
      disableSorting: true,
      userauthority: 1,
    },
    {
      id: "customerName",
      label: "담당자명",
      userauthority: 1,
      disableSorting: true,
    },
    {
      id: "customerPhone",
      label: "담당자 번호",
      disableSorting: true,
      userauthority: 1,
    },
    {
      id: "customerEmail",
      label: "담당자 이메일",
      disableSorting: true,
      userauthority: 1,
    },
    {
      id: "Quotation",
      label: "발주서/품목",
      disableSorting: true,
      userauthority: user?.userauthority > 0 ? 1 : 0,
    },
  ];

  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [memoOpenPopup, setMemoOpenPopup] = useState(false);
  const [orderItemOpenPopup, setOrderItemOpenPopup] = useState(false);
  const [quotationOpenPopup, setQuotationOpenPopup] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await orderingCompanyService.getAllCompany();
        setRecords(response);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);

  const handleSearch = (e) => {
    const {
      target: { value },
    } = e;
    return value;
  };

  const handleKeyPress = (e) => {
    const value = handleSearch(e);
    if (e.key === "Enter") {
      setFilterFn({
        fn: (items) => {
          if (value == "") return items;
          return items.filter(
            (x) =>
              x.createdAt?.toLowerCase().includes(value) ||
              x.customerName?.toLowerCase().includes(value) ||
              x.customerPhone?.toLowerCase().includes(value) ||
              x.customerPhoneTwo?.toLowerCase().includes(value) ||
              x.customerEmail?.toLowerCase().includes(value) ||
              x.customerEmailTwo?.toLowerCase().includes(value) ||
              x.customerStore?.toLowerCase().includes(value) ||
              x.customerKeyTech?.toLowerCase().includes(value) ||
              x.customerStatus?.toLowerCase().includes(value)
          );
        },
      });
    }
  };

  const addOrEdit = async (employee) => {
    console.log(employee);
    if (employee.id == 0) {
      const result = await orderingCompanyService.insertCompany(employee);
      if (result === "denied") {
        return result;
      }
    } else {
      await orderingCompanyService.updateCompany(employee);
    }

    setRecordForEdit(null);
    setRecords(await orderingCompanyService.getAllCompany());
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const quotationOpenInPopup = (item) => {
    setRecordForEdit({
      id: item.id,
      customerStore: item.companyName,
      customerLocation: item.companyLocation,
      customerPhone: item.customerPhone,
      customerName: item.customerName,
      customerEmail: item.customerEmail,
      companyitem: item.companyitem,
    });
    setQuotationOpenPopup(true);
  };

  const orderItemOpenInPopup = (item) => {
    setRecordForEdit({ id: item.id, companyitem: item.companyitem });
    setOrderItemOpenPopup(true);
  };

  return (
    <Wrapper>
      <PageHeader
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
        title="벤디트 거래처 관리"
        subTitle="거래처 고객 관리 및 등록"
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="고객 검색"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
            onKeyPress={handleKeyPress}
          />
          <div
            style={{
              width: "60%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Controls.Button
              text="고객 등록"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            />
          </div>
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {records &&
              recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell className={classes.tblCell}>
                    <Controls.Input
                      style={{ width: "40px" }}
                      name={"test2"}
                      placeholder={
                        item.columorder != null ? item.columorder : ""
                      }
                      // value={item.columorder != null ? item.columorder : "none"}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.tblCell}
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    {item.createdAt.slice(2, 10)}
                    {/* <div style={{ fontSize: '10px' }}>
                      {item.user !== null && `작성:${item.user.username}`}
                    </div> */}
                  </TableCell>
                  <TableCell
                    className={classes.tblCell}
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    {item.companyName}
                  </TableCell>
                  <TableCell
                    className={classes.tblCell}
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    {item.companyRegisterNumber}
                  </TableCell>
                  <TableCell
                    className={classes.tblCell}
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    <BottomLine isWebOpen={item.bankName}>
                      {item.bankName}
                    </BottomLine>
                    <BottomLine isWebOpen={item.accountNum}>
                      {item.accountNum}
                    </BottomLine>
                  </TableCell>
                  <TableCell
                    className={classes.tblCell}
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    {item.customerName}
                  </TableCell>
                  <TableCell
                    className={classes.tblCell}
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    {item.customerPhone}
                  </TableCell>
                  <TableCell
                    className={classes.tblCell}
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    {item.customerEmail}
                  </TableCell>
                  {user?.userauthority > 0 && (
                    <TableCell className={classes.tblCell}>
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          quotationOpenInPopup(item);
                        }}
                      >
                        <FaMailBulk />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          orderItemOpenInPopup(item);
                        }}
                      >
                        <FaFileContract />
                      </Controls.ActionButton>
                      {/* <div>
                        <Controls.ActionButton
                          color="secondary"
                          onClick={() => {
                            contractOpenInPopup(item);
                          }}
                        >
                          <FaMailBulk />
                        </Controls.ActionButton>
                        <Controls.ActionButton
                          color="secondary"
                          onClick={() => {
                            contractDetailsOpenInPopup(item);
                          }}
                        >
                          <FaFileAlt />
                        </Controls.ActionButton>
                      </div> */}
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
      <Popup
        title="고객 관리"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <RegisterForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
          setRecords={setRecords}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
      <Popup
        title="발주서 발송"
        openPopup={quotationOpenPopup}
        setOpenPopup={setQuotationOpenPopup}
      >
        <OrderQuotationForm
          recordForEdit={recordForEdit}
          setOpenPopup={setQuotationOpenPopup}
          setRecords={setRecords}
        />
      </Popup>
      <Popup
        title="거래처별 품목 설정"
        openPopup={orderItemOpenPopup}
        setOpenPopup={setOrderItemOpenPopup}
        sx={{ width: "500px" }}
      >
        <RegisterOrderItem
          addOrEdit={addOrEdit}
          recordForEdit={recordForEdit}
          setOpenPopup={setOrderItemOpenPopup}
          setRecords={setRecords}
        />
      </Popup>

      <Controls.Button text="저장" />
    </Wrapper>
  );
};

export default OrderingCompanyMain;
