import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useForm, Form } from "../common/useForm";
import Controls from "../common/FormType/Controls";
import { PdfDocument } from "./QuotationOrderDocs";
import Notification from "../common/Notification";
import QuotationAddFormData from "./QuotationAddFormData";

const initialValues = {
  itemName: [],
  itemUnitPrice: [],
  itemUnit: [],
  itemOther: [],
  customerStore: [],
  orderWritingDate: new Date().toISOString(),
  orderSelectType: 0,
  itemQuantity: [],
  itemUnitPrice: [],
  orderRemarks: "",
};

export default function QuotationOrderForm({ recordForEdit, user }) {
  const [show, setHide] = useState(false);
  const [showItem, setShowItem] = useState(false);
  const [orderItem, setOrderItem] = useState([]);
  const [itemData, setItemdata] = useState({
    itemName: "",
    itemUnit: "",
    itemQuantity: "",
    itemUnitPrice: "",
    itemOther: "",
  });
  const [itemArr, setItemarr] = useState([]);
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);
  const [finalItemArr, setFinalItemArr] = useState([]);
  const [finalValues, setFinalValues] = useState(initialValues);
  const AMOUNT = 16;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setHide(true);
  };

  const onModifyArrays = useCallback(
    (localarray, localindex) => {
      const fixedArrya = itemArr.map((item, index) => {
        if (localindex === index) {
          return localarray;
        }
        return item;
      });
      setItemarr(fixedArrya);
    },
    [itemArr]
  );

  const inputIncreased = async (e) => {
    e.preventDefault();
    setShowItem(true);

    console.log(orderItem);

    let temp =
      (await values.orderSelectType) > 0
        ? {
            itemName: orderItem[values.orderSelectType - 1].account
              ? orderItem[values.orderSelectType - 1].account
              : orderItem[values.orderSelectType - 1].item,
            price: orderItem[values.orderSelectType - 1].price,
            itemOther: orderItem[values.orderSelectType - 1].other,
            standard: orderItem[values.orderSelectType - 1].standard,
          }
        : { itemName: "", price: "", itemOther: "" };

    if (itemArr?.length < AMOUNT) {
      setValues({
        ...values,
        itemName: values.itemName.concat(temp.itemName),
        itemUnitPrice: values.itemUnitPrice.concat(temp.price),
        itemUnit: values.itemUnit.concat(temp.standard),
        itemOther: values.itemOther.concat(temp.itemOther),
      });

      setItemdata({
        itemName: temp.itemName,
        itemUnit: temp.standard,
        itemQuantity: values.itemQuantity,
        itemUnitPrice: temp.price,
        itemOther: temp.itemOther,
      });
    } else {
      setNotify({
        isOpen: true,
        message: "더 이상 추가하실 수 없습니다!!",
        type: "warning",
      });
    }
    setHide(false);
  };

  const handleOrderDocsInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const orderSelectLoop = () => {
    if (orderItem.length === 0)
      return [{ id: 0, title: "품목을 선택해 주세요" }];
    const loop = orderItem.map((item, index) => {
      return item.price > 0 ? { id: index + 1, title: item.item } : null;
    });
    const result = [{ id: 0, title: "품목을 선택해 주세요" }, ...loop];
    return result;
  };

  // ======================= useEffect 절취선 =======================

  useEffect(() => {
    if (showItem) {
      setItemarr(itemArr.concat(itemData));
    }
  }, [itemData]);

  useEffect(() => {
    if (recordForEdit != null) {
      const { companyitem, ...rest } = recordForEdit;
      let changeParse = [];
      if (companyitem !== "") {
        changeParse = JSON.parse(companyitem);
      }
      setValues({
        ...values,
        ...rest,
        ...user,
      });
      setOrderItem(changeParse);
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Notification notify={notify} setNotify={setNotify} />
      <Grid container alignContent="center">
        <Grid item xs={6}>
          <Controls.Input
            variant="outlined"
            label="업체명"
            name="customerStore"
            placeholder="Placeholder"
            value={values.customerStore}
            onChange={handleInputChange}
          />

          <Controls.Input
            variant="outlined"
            label="납품업소명"
            name="sendingStore"
            value={values.sendingStore}
            onChange={handleInputChange}
          />
          <Controls.Input
            variant="outlined"
            label="납품장소"
            name="sendingLocation"
            value={values.sendingLocation}
            onChange={handleInputChange}
          />
          {/* <Controls.Input
            variant="outlined"
            label="주소"
            name="customerLocation"
            value={values.customerLocation}
            onChange={handleInputChange}
          /> */}
        </Grid>
        <Grid item xs={6}>
          <Controls.DatePicker
            variant="outlined"
            label="납품희망일"
            name="orderWritingDate"
            value={values.orderWritingDate}
            onChange={handleInputChange}
          />
          <Controls.ObjectSelect
            label="품목선택"
            name="orderSelectType"
            value={values.orderSelectType}
            onChange={handleOrderDocsInputChange}
            options={orderSelectLoop()}
          />

          <Controls.Button
            onClick={inputIncreased}
            text={`품목 추가(최대${AMOUNT}개)`}
          />
        </Grid>
        {itemArr.map((data, index) => {
          return (
            <QuotationAddFormData
              values={data}
              index={index}
              arrays={itemArr}
              setValues={setItemarr}
              onModifyArrays={onModifyArrays}
            />
          );
        })}

        <Grid item xs={12}>
          <Controls.Input
            id="outlined-multiline-static"
            label="비고작성"
            name="orderRemarks"
            multiline
            rows={2}
            variant="outlined"
            type="number"
            value={values.orderRemarks}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Button
            type="submit"
            text="발주서생성"
            onClick={(e) => {
              setFinalItemArr(itemArr);
              setFinalValues(values);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          {show && (
            <PDFDownloadLink
              document={
                <PdfDocument data={finalItemArr} userValue={finalValues} />
              }
              fileName={`${values.customerStore}_발주서.pdf`}
              style={{
                marginLeft: "5px",
                display: "flex",
                textDecoration: "none",
                padding: "10px",
                color: "#4a4a4a",
                backgroundColor: "#f2f2f2",
                border: "1px solid #4a4a4a",
              }}
            >
              {({ blob, url, loading, error }) =>
                loading ? "로딩중" : "PDF 다운로드"
              }
            </PDFDownloadLink>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}
