import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import * as customerService from '../../lib/api/FormService/CustomerService';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    textAlign: 'center',
  },
});

export default function ContractDetailsTable({ recordForEdit }) {
  const classes = useStyles();
  const [customerContract, setCustomerContract] = useState([]);

  useEffect(() => {
    if (recordForEdit) {
      const fetchData = async () => {
        try {
          const response = await Axios.get(
            'https://api.glosign.com/v1/contract/list?from=20200120&to=20211230&clientid=ue8969793f8cf51d8e8eef98b90fdd449',
            {
              headers: {
                Authorization: 'Bearer lxCL35e2L6SgEO3dvDpd1idW1',
              },
            }
          );
          const dbCustomerContractresponse = await customerService.getCustomerContract(
            recordForEdit.id
          );
          const result = dbCustomerContractresponse.map((item) => {
            let filter = response.data.contractList.filter((filterItem) => {
              if (filterItem.contract_id === item.contractId) {
                return filterItem;
              }
            });
            if (filter[0].contract_id === item.contractId) {
              let filteredStatus;
              if (filter[0].contract_status === 'complete') {
                filteredStatus = '계약완료';
              } else {
                filteredStatus = '진행중';
              }
              return {
                ...item,
                contract_status: filteredStatus,
                title: filter[0].title,
              };
            }
          });
          setCustomerContract(result);
        } catch (e) {
          console.log(e);
        }
      };
      fetchData();
    }
  }, []);
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>발송일</TableCell>
            <TableCell align="center">계약서명</TableCell>
            <TableCell align="center">진행상태</TableCell>
            <TableCell align="center">담당자</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customerContract &&
            customerContract.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.createdAt.slice(0, 10)}
                </TableCell>
                <TableCell align="center">{row.title}</TableCell>
                <TableCell align="center">{row.contract_status}</TableCell>
                <TableCell align="center">{row.username}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
