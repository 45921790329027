import React from "react";
import { TextField } from "@material-ui/core";

// error 기본은 null true일시 helpertext 발동
export default function Input(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    defaultValue,
    ...other
  } = props;
  return (
    <TextField
      variant="outlined"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  );
}
