import React, { useState } from "react";
import {
  PDFDownloadLink,
  BlobProvider,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  pdf,
  Font,
} from "@react-pdf/renderer";
import QuotationImage from "../../lib/images/newKioskSubQutation.png";

Font.register({
  family: "Nanum Gothic",
  fonts: [
    {
      src: "https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Regular.ttf",
      fontWeight: 100,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    width: "100%",
    height: "100%",
    // orientation: 'portrait',
  },
  view: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    padding: 0,
    backgroundColor: "white",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
    // margin: '10',
  },
  sectioncustomerName: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 275,
    left: 135,
    width: "200px",
    // textAlign: "justify",
  },
  customerName: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
    fontWeight: "thin",
  },
  username: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 212,
    left: 135,
    // fontWeight: 200,
    fontSize: 8,
  },
  userphone: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 227,
    left: 135,
    fontSize: 8,
  },
  sectionKioskType: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 380,
    left: 57,
    width: "80px",
    // textAlign: "justify",
  },
  kioskType: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  kioskTypeEA: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 380,
    left: 156,
    fontSize: 8,
  },
  kioskAmount: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 380,
    left: 222,
    fontSize: 8,
  },
  kioskPrice: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 380,
    left: 250,
    fontSize: 8,
  },
  kioskAmountTotal: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 380,
    left: 310,
    fontSize: 8,
  },
  kioskAmountTax: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 380,
    left: 382,
    fontSize: 8,
  },
  kioskRemark: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 380,
    left: 463,
    fontSize: 8,
  },
  totalPrice: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 735,
    left: 310,
    fontSize: 8,
  },
  totalTax: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 735,
    left: 382,
    fontSize: 8,
  },
  totalPriceATax: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 735,
    left: 463,
    fontSize: 8,
  },
  aboveTotal: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 735,
    left: 463,
    fontSize: 8,
  },
  // S/W
  sectionServiceName: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 405,
    left: 57,
    width: "80px",
    // textAlign: "justify",
  },
  serviceName: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  serviceEA: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 405,
    left: 156,
    fontSize: 8,
  },
  serviceAmount: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 405,
    left: 222,
    fontSize: 8,
  },
  // 관리키
  adminRoomkeyName: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 430,
    left: 57,
    fontSize: 8,
  },
  adminRoomkeyEA: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 430,
    left: 156,
    fontSize: 8,
  },
  adminRoomkey: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 430,
    left: 222,
    fontSize: 8,
  },
  customerRoomkeyName: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 455,
    left: 57,
    fontSize: 8,
  },
  customerRoomkeyEA: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 455,
    left: 156,
    fontSize: 8,
  },
  customerRoomkey: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 455,
    left: 222,
    fontSize: 8,
  },
  // 추가품목
  section: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 480,
    left: 57,
    width: "80px",
  },
  kioskAddItem: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  sectionkioskAddItemDetail: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 480,
    left: 156,
    width: "50px",
  },
  kioskAddItemDetail: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 수량
  sectionkioskAddItemAmount: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 480,
    left: 222,
    width: "20px",
  },
  kioskAddItemAmount: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 단가
  sectionkioskAddItemPrice: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 480,
    left: 250,
    width: "40px",
  },
  kioskAddItemPrice: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 공급가액
  sectionkioskAddItemAmountPrice: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 480,
    left: 310,
    width: "50px",
  },
  kioskAddItemAmountPrice: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 세액
  sectionkioskAddItemAmountPriceTax: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 480,
    left: 382,
    width: "50px",
  },
  kioskAddItemAmountPriceTax: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  sectionkioskAddItemRemarks: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 480,
    left: 463,
    width: "80px",
  },
  kioskAddItemRemarks: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 두번쨰
  sectionTwo: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 505,
    left: 57,
    width: "80px",
  },
  kioskAddItemTwo: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  sectionkioskAddItemDetailTwo: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 505,
    left: 156,
    width: "50px",
  },
  kioskAddItemDetailTwo: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 수량
  sectionkioskAddItemAmountTwo: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 505,
    left: 222,
    width: "20px",
  },
  kioskAddItemAmountTwo: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 단가
  sectionkioskAddItemPriceTwo: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 505,
    left: 250,
    width: "40px",
  },
  kioskAddItemPriceTwo: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 공급가액
  sectionkioskAddItemAmountPriceTwo: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 505,
    left: 310,
    width: "50px",
  },
  kioskAddItemAmountPriceTwo: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 세액
  sectionkioskAddItemAmountPriceTaxTwo: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 505,
    left: 382,
    width: "50px",
  },
  kioskAddItemAmountPriceTaxTwo: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  sectionkioskAddItemRemarksTwo: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 505,
    left: 463,
    width: "80px",
  },
  kioskAddItemRemarksTwo: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 세번쨰
  sectionThree: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 530,
    left: 57,
    width: "80px",
  },
  kioskAddItemThree: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  sectionkioskAddItemDetailThree: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 530,
    left: 156,
    width: "50px",
  },
  kioskAddItemDetailThree: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 수량
  sectionkioskAddItemAmountThree: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 530,
    left: 222,
    width: "20px",
  },
  kioskAddItemAmountThree: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 단가
  sectionkioskAddItemPriceThree: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 530,
    left: 250,
    width: "40px",
  },
  kioskAddItemPriceThree: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 공급가액
  sectionkioskAddItemAmountPriceThree: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 530,
    left: 310,
    width: "50px",
  },
  kioskAddItemAmountPriceThree: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 세액
  sectionkioskAddItemAmountPriceTaxThree: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 530,
    left: 382,
    width: "50px",
  },
  kioskAddItemAmountPriceTaxThree: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  sectionkioskAddItemRemarksThree: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 530,
    left: 463,
    width: "80px",
  },
  kioskAddItemRemarksThree: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 네번째
  sectionFour: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 555,
    left: 57,
    width: "80px",
  },
  kioskAddItemFour: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  sectionkioskAddItemDetailFour: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 555,
    left: 156,
    width: "50px",
  },
  kioskAddItemDetailFour: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 수량
  sectionkioskAddItemAmountFour: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 555,
    left: 222,
    width: "20px",
  },
  kioskAddItemAmountFour: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 단가
  sectionkioskAddItemPriceFour: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 555,
    left: 250,
    width: "40px",
  },
  kioskAddItemPriceFour: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 공급가액
  sectionkioskAddItemAmountPriceFour: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 555,
    left: 310,
    width: "50px",
  },
  kioskAddItemAmountPriceFour: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 세액
  sectionkioskAddItemAmountPriceTaxFour: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 555,
    left: 382,
    width: "50px",
  },
  kioskAddItemAmountPriceTaxFour: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  sectionkioskAddItemRemarksFour: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 555,
    left: 463,
    width: "80px",
  },
  kioskAddItemRemarksFour: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 다섯번째
  sectionFive: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 580,
    left: 57,
    width: "80px",
  },
  kioskAddItemFive: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  sectionkioskAddItemDetailFive: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 580,
    left: 156,
    width: "50px",
  },
  kioskAddItemDetailFive: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 수량
  sectionkioskAddItemAmountFive: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 580,
    left: 222,
    width: "20px",
  },
  kioskAddItemAmountFive: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 단가
  sectionkioskAddItemPriceFive: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 580,
    left: 250,
    width: "40px",
  },
  kioskAddItemPriceFive: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 공급가액
  sectionkioskAddItemAmountPriceFive: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 580,
    left: 310,
    width: "50px",
  },
  kioskAddItemAmountPriceFive: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 추가품목 세액
  sectionkioskAddItemAmountPriceTaxFive: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 580,
    left: 382,
    width: "50px",
  },
  kioskAddItemAmountPriceTaxFive: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  sectionkioskAddItemRemarksFive: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 580,
    left: 463,
    width: "80px",
  },
  kioskAddItemRemarksFive: {
    fontFamily: "Nanum Gothic",
    fontSize: 8,
  },
  // 월 서비스 개선 관리 비용
  monthlyPrice: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 636,
    left: 250,
    fontSize: 8,
  },
  monthlySupplyPrice: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 636,
    left: 310,
    fontSize: 8,
  },
  monthlyPriceTax: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 636,
    left: 382,
    fontSize: 8,
  },
  monthlyFeeRemark: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 636,
    left: 463,
    fontSize: 8,
  },
  // 작성일
  quotationDate: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 290,
    left: 135,
    fontSize: 8,
  },
  // 기타사항
  sectionkioskAddMemo: {
    position: "absolute",
    fontFamily: "Nanum Gothic",
    top: 785,
    left: 58,
    width: "478px",
    // textAlign: "justify",
  },
  kioskAddMemo: {
    fontFamily: "Nanum Gothic",
    lineHeight: 1.3,
    fontSize: 8,
    // textAlign: "justify",
  },
});

export function PdfDocument({ data }) {
  const sum =
    data.kioskAddItemPrice * data.kioskAddItemAmount +
    data.kioskAddItemPriceTwo * data.kioskAddItemAmountTwo +
    data.kioskAddItemPriceThree * data.kioskAddItemAmountThree +
    data.kioskAddItemPriceFour * data.kioskAddItemAmountFour +
    data.kioskAddItemPriceFive * data.kioskAddItemAmountFive;
  return (
    <Document>
      <Page style={styles.page} size="A4">
        <View style={styles.view}>
          <Image style={styles.image} src={QuotationImage} alt="images" />
          <View style={styles.sectioncustomerName}>
            <Text style={styles.customerName}>{data.customerStore}</Text>
          </View>
          <Text style={styles.quotationDate}>
            {data.quotationDate.slice(0, 10)}
          </Text>
          <Text style={styles.username}>{data.username}</Text>
          <Text style={styles.userphone}>{data.userphone}</Text>
          <View style={styles.sectionKioskType}>
            <Text style={styles.kioskType}>{data.kioskType}</Text>
          </View>
          <Text style={styles.kioskTypeEA}>{data.kioskTypeEA}</Text>
          <Text style={styles.kioskAmount}>{data.subAmount}</Text>
          <Text style={styles.kioskPrice}>
            {(data.subPrice * "1").toLocaleString("ko-KR")}
          </Text>
          <Text style={styles.kioskAmountTotal}>
            {(data.subAmount * data.subPrice).toLocaleString("ko-KR")}
          </Text>
          <Text style={styles.kioskAmountTax}>
            {(data.subAmount * data.subPrice * "0.1").toLocaleString("ko-KR")}
          </Text>
          <Text style={styles.kioskRemark}>{data.kioskRemark}</Text>
          {/* S/W */}
          <View style={styles.sectionServiceName}>
            <Text style={styles.serviceName}>{data.serviceName}</Text>
          </View>
          <Text style={styles.serviceEA}>{data.serviceEA}</Text>
          <Text style={styles.serviceAmount}>{data.kioskAmount}</Text>
          {/* 관리키 */}
          <Text style={styles.adminRoomkeyName}>13.56mhz 관리자 객실키</Text>
          <Text style={styles.adminRoomkeyEA}>EA</Text>
          <Text style={styles.adminRoomkey}>{data.adminRoomkey}</Text>
          <Text style={styles.customerRoomkeyName}>13.56mhz 사용자 객실키</Text>
          <Text style={styles.customerRoomkeyEA}>EA</Text>
          <Text style={styles.customerRoomkey}>{data.customerRoomkey}</Text>
          {/* 추가품목 */}
          <View style={styles.section}>
            <Text style={styles.kioskAddItem}>{data.kioskAddItem}</Text>
          </View>
          <View style={styles.sectionkioskAddItemDetail}>
            <Text style={styles.kioskAddItemDetail}>
              {data.kioskAddItemDetail}
            </Text>
          </View>
          {/* 추가품목 수량 */}
          <View style={styles.sectionkioskAddItemAmount}>
            <Text style={styles.kioskAddItemAmount}>
              {data.kioskAddItemAmount}
            </Text>
          </View>
          {/* 추가품목 단가 */}
          <View style={styles.sectionkioskAddItemPrice}>
            <Text style={styles.kioskAddItemPrice}>
              {data.kioskAddItemAmount &&
                data.kioskAddItemPrice &&
                (data.kioskAddItemPrice * "1").toLocaleString("ko-KR")}
            </Text>
          </View>
          {/* 추가품목 공급가액 */}
          <View style={styles.sectionkioskAddItemAmountPrice}>
            <Text style={styles.kioskAddItemAmountPrice}>
              {data.kioskAddItemAmount &&
                data.kioskAddItemPrice &&
                (
                  data.kioskAddItemPrice * data.kioskAddItemAmount
                ).toLocaleString("ko-KR")}
            </Text>
          </View>
          {/* 추가품목 세액 */}
          <View style={styles.sectionkioskAddItemAmountPriceTax}>
            <Text style={styles.kioskAddItemAmountPriceTax}>
              {data.kioskAddItemAmount &&
                data.kioskAddItemPrice &&
                Math.round(
                  data.kioskAddItemPrice * data.kioskAddItemAmount * "0.1"
                ).toLocaleString("ko-KR")}
            </Text>
          </View>
          <View style={styles.sectionkioskAddItemRemarks}>
            <Text style={styles.kioskAddItemRemarks}>
              {data.kioskAddItemRemarks}
            </Text>
          </View>
          {/* 추가품목 2 */}
          <View style={styles.sectionTwo}>
            <Text style={styles.kioskAddItemTwo}>{data.kioskAddItemTwo}</Text>
          </View>
          <View style={styles.sectionkioskAddItemDetailTwo}>
            <Text style={styles.kioskAddItemDetailTwo}>
              {data.kioskAddItemDetailTwo}
            </Text>
          </View>
          {/* 추가품목 수량 */}
          <View style={styles.sectionkioskAddItemAmountTwo}>
            <Text style={styles.kioskAddItemAmountTwo}>
              {data.kioskAddItemAmountTwo}
            </Text>
          </View>
          {/* 추가품목 단가 */}
          <View style={styles.sectionkioskAddItemPriceTwo}>
            <Text style={styles.kioskAddItemPriceTwo}>
              {data.kioskAddItemPriceTwo &&
                (data.kioskAddItemPriceTwo * "1").toLocaleString("ko-KR")}
            </Text>
          </View>
          {/* 추가품목 공급가액 */}
          <View style={styles.sectionkioskAddItemAmountPriceTwo}>
            <Text style={styles.kioskAddItemAmountPriceTwo}>
              {data.kioskAddItemPriceTwo &&
                (
                  data.kioskAddItemPriceTwo * data.kioskAddItemAmountTwo
                ).toLocaleString("ko-KR")}
            </Text>
          </View>
          {/* 추가품목 세액 */}
          <View style={styles.sectionkioskAddItemAmountPriceTaxTwo}>
            <Text style={styles.kioskAddItemAmountPriceTaxTwo}>
              {data.kioskAddItemPriceTwo &&
                Math.round(
                  data.kioskAddItemPriceTwo * data.kioskAddItemAmountTwo * "0.1"
                ).toLocaleString("ko-KR")}
            </Text>
          </View>
          <View style={styles.sectionkioskAddItemRemarksTwo}>
            <Text style={styles.kioskAddItemRemarksTwo}>
              {data.kioskAddItemRemarksTwo}
            </Text>
          </View>
          {/* 추가품목 3 */}
          <View style={styles.sectionThree}>
            <Text style={styles.kioskAddItemThree}>
              {data.kioskAddItemThree}
            </Text>
          </View>
          <View style={styles.sectionkioskAddItemDetailThree}>
            <Text style={styles.kioskAddItemDetailThree}>
              {data.kioskAddItemDetailThree}
            </Text>
          </View>
          {/* 추가품목 수량 */}
          <View style={styles.sectionkioskAddItemAmountThree}>
            <Text style={styles.kioskAddItemAmountThree}>
              {data.kioskAddItemAmountThree}
            </Text>
          </View>
          {/* 추가품목 단가 */}
          <View style={styles.sectionkioskAddItemPriceThree}>
            <Text style={styles.kioskAddItemPriceThree}>
              {data.kioskAddItemPriceThree &&
                (data.kioskAddItemPriceThree * "1").toLocaleString("ko-KR")}
            </Text>
          </View>
          {/* 추가품목 공급가액 */}
          <View style={styles.sectionkioskAddItemAmountPriceThree}>
            <Text style={styles.kioskAddItemAmountPriceThree}>
              {data.kioskAddItemPriceThree &&
                (
                  data.kioskAddItemPriceThree * data.kioskAddItemAmountThree
                ).toLocaleString("ko-KR")}
            </Text>
          </View>
          {/* 추가품목 세액 */}
          <View style={styles.sectionkioskAddItemAmountPriceTaxThree}>
            <Text style={styles.kioskAddItemAmountPriceTaxThree}>
              {data.kioskAddItemPriceThree &&
                Math.round(
                  data.kioskAddItemPriceThree *
                    data.kioskAddItemAmountThree *
                    "0.1"
                ).toLocaleString("ko-KR")}
            </Text>
          </View>
          <View style={styles.sectionkioskAddItemRemarksThree}>
            <Text style={styles.kioskAddItemRemarksThree}>
              {data.kioskAddItemRemarksThree}
            </Text>
          </View>
          {/* 추가품목 4 */}
          <View style={styles.sectionFour}>
            <Text style={styles.kioskAddItemFour}>{data.kioskAddItemFour}</Text>
          </View>
          <View style={styles.sectionkioskAddItemDetailFour}>
            <Text style={styles.kioskAddItemDetailFour}>
              {data.kioskAddItemDetailFour}
            </Text>
          </View>
          {/* 추가품목 수량 */}
          <View style={styles.sectionkioskAddItemAmountFour}>
            <Text style={styles.kioskAddItemAmountFour}>
              {data.kioskAddItemAmountFour}
            </Text>
          </View>
          {/* 추가품목 단가 */}
          <View style={styles.sectionkioskAddItemPriceFour}>
            <Text style={styles.kioskAddItemPriceFour}>
              {data.kioskAddItemPriceFour &&
                (data.kioskAddItemPriceFour * "1").toLocaleString("ko-KR")}
            </Text>
          </View>
          {/* 추가품목 공급가액 */}
          <View style={styles.sectionkioskAddItemAmountPriceFour}>
            <Text style={styles.kioskAddItemAmountPriceFour}>
              {data.kioskAddItemPriceFour &&
                (
                  data.kioskAddItemPriceFour * data.kioskAddItemAmountFour
                ).toLocaleString("ko-KR")}
            </Text>
          </View>
          {/* 추가품목 세액 */}
          <View style={styles.sectionkioskAddItemAmountPriceTaxFour}>
            <Text style={styles.kioskAddItemAmountPriceTaxFour}>
              {data.kioskAddItemPriceFour &&
                Math.round(
                  data.kioskAddItemPriceFour *
                    data.kioskAddItemAmountFour *
                    "0.1"
                ).toLocaleString("ko-KR")}
            </Text>
          </View>
          <View style={styles.sectionkioskAddItemRemarksFour}>
            <Text style={styles.kioskAddItemRemarksFour}>
              {data.kioskAddItemRemarksFour}
            </Text>
          </View>
          {/* 추가품목 5 */}
          <View style={styles.sectionFive}>
            <Text style={styles.kioskAddItemFive}>{data.kioskAddItemFive}</Text>
          </View>
          <View style={styles.sectionkioskAddItemDetailFive}>
            <Text style={styles.kioskAddItemDetailFive}>
              {data.kioskAddItemDetailFive}
            </Text>
          </View>
          {/* 추가품목 수량 */}
          <View style={styles.sectionkioskAddItemAmountFive}>
            <Text style={styles.kioskAddItemAmountFive}>
              {data.kioskAddItemAmountFive}
            </Text>
          </View>
          {/* 추가품목 단가 */}
          <View style={styles.sectionkioskAddItemPriceFive}>
            <Text style={styles.kioskAddItemPriceFive}>
              {data.kioskAddItemPriceFive &&
                (data.kioskAddItemPriceFive * "1").toLocaleString("ko-KR")}
            </Text>
          </View>
          {/* 추가품목 공급가액 */}
          <View style={styles.sectionkioskAddItemAmountPriceFive}>
            <Text style={styles.kioskAddItemAmountPriceFive}>
              {data.kioskAddItemPriceFive &&
                (
                  data.kioskAddItemPriceFive * data.kioskAddItemAmountFive
                ).toLocaleString("ko-KR")}
            </Text>
          </View>
          {/* 추가품목 세액 */}
          <View style={styles.sectionkioskAddItemAmountPriceTaxFive}>
            <Text style={styles.kioskAddItemAmountPriceTaxFive}>
              {data.kioskAddItemPriceFive &&
                Math.round(
                  data.kioskAddItemPriceFive *
                    data.kioskAddItemAmountFive *
                    "0.1"
                ).toLocaleString("ko-KR")}
            </Text>
          </View>
          <View style={styles.sectionkioskAddItemRemarksFive}>
            <Text style={styles.kioskAddItemRemarksFive}>
              {data.kioskAddItemRemarksFive}
            </Text>
          </View>
          {/* 합계 */}
          <Text style={styles.totalPrice}>
            {Math.round(sum).toLocaleString("ko-KR")}
          </Text>
          <Text style={styles.totalTax}>
            {Math.round(sum * "0.1").toLocaleString("ko-KR")}
          </Text>
          <Text style={styles.totalPriceATax}>
            {Math.round(sum * "0.1" + sum).toLocaleString("ko-KR")}
          </Text>
          {/* <Text style={styles.aboveTotal}>{sum.toLocaleString("ko-KR")}</Text> */}
          {/* 월관리비용 */}
          <Text style={styles.monthlyPrice}>
            {(data.monthlyFee * "1").toLocaleString("ko-KR")}
          </Text>
          <Text style={styles.monthlySupplyPrice}>
            {(data.monthlyFee * "1").toLocaleString("ko-KR")}
          </Text>
          <Text style={styles.monthlyPriceTax}>
            {(data.monthlyFee * "1" * "0.1").toLocaleString("ko-KR")}
          </Text>
          <Text style={styles.monthlyFeeRemark}>{data.monthlyFeeRemark}</Text>
          {/* 기타사항 */}
          <View style={styles.sectionkioskAddMemo}>
            <Text style={styles.kioskAddMemo}>
              {data.kioskAddMemo && data.kioskAddMemo}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
