import React from "react";
import { useSelector } from "react-redux";
import QutationBizcardForm from "../Quotation/QutationBizcardForm";

export default function QuotationForm(props) {
  const { recordForEdit, location, setOpenPopup, setResult } = props;
  const { user } = useSelector(({ user }) => ({ user: user.user }));
  const id = location === "edit" ? recordForEdit.id : user.id;
  return (
    <>
      <QutationBizcardForm
        Id={id}
        setOpenPopup={setOpenPopup}
        setResult={setResult}
      />
    </>
  );
}
