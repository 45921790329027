import React, { useEffect } from "react";
import Axios from "axios";
import { Grid } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useForm, Form } from "../common/useForm";
import Controls from "../common/FormType/Controls";
import * as customerService from "../../lib/api/FormService/CustomerService";
import * as orderingCompanyService from "../../lib/api/FormService/OrderingCompanyService";

export default function OtherFeaturesSMS(props) {
  const [checked, setChecked] = React.useState(false);
  const [proposalChecked, setProposalChecked] = React.useState(false);
  const [sendSMSChecked, setSendSMSChecked] = React.useState(true);
  const {
    quotationType,
    recordForEdit,
    setOpenPopup,
    setRecords,
    quotationNumPrice,
    user,
  } = props;

  const initialValues = {
    platFormType: "",
    smsType: "",
    customerStore: "",
    customerPhone: "",
    roomType: "",
    storeName: "",
    entranceHour: "",
    exitHour: "",
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const handleSendSMSCheckdChange = (event) => {
    setSendSMSChecked(event.target.checked);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      !values.platFormType ||
      !values.smsType ||
      !values.customerStore ||
      !values.customerPhone ||
      !values.roomType ||
      !values.storeName
    ) {
      alert("항목을 모두 작성해 주세요");
      return;
    }
    const result = await customerService.sendSMS(values);

    alert("sms발송완료");
    // setValues({
    //   smsType: "",
    //   platFormType: "",
    //   customerStore: "",
    //   customerPhone: "",
    //   roomType: "",
    //   storeName: "",
    // });
  };

  const getPlatFormTypeCollection = () => [
    { id: 1, title: "여기어때" },
    { id: 2, title: "야놀자" },
    // { id: 3, title: "네이버" },
  ];

  const getSmsTypeCollection = (platform) => {
    let changeSmsType;
    if (!platform) {
      changeSmsType = [];
    } else if (platform === 1) {
      changeSmsType = [
        { id: 1, title: "당일숙박" },
        { id: 2, title: "당일임박" },
        { id: 3, title: "미리숙박 " },
        // { id: 4, title: "미리대실" },
        // { id: 5, title: "미리숙박 당일임박" },
        // { id: 6, title: "미리대실 당일임박" },
        // { id: 7, title: "미리예약 대실취소" },
      ];
    } else if (platform === 2) {
      changeSmsType = [
        { id: 1, title: "당일숙박" },
        { id: 2, title: "당일임박" },
        { id: 3, title: "미리숙박" },
        // { id: 4, title: "미리숙박 당일임박 2건" },
        // { id: 5, title: "미리대실 당일임박 3건" },
        // { id: 6, title: "미리예약 대실취소" },
      ];
    }
    // else if (platform === 3) {
    //   changeSmsType = [
    //     { id: 1, title: "최초예약알림" },
    //     { id: 2, title: "당일 예약 발생(확정됨)" },
    //     { id: 3, title: "예약 취소" },
    //     { id: 4, title: "예약 취소2" },
    //     { id: 5, title: "예약 발생(확정,연박)" },
    //   ]
    // }
    return changeSmsType;
  };

  return (
    <div className="container">
      <Form onSubmit={onSubmit}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item xs={8}>
            <Controls.Select
              name="platFormType"
              label="플랫폼"
              value={values.platFormType}
              onChange={handleInputChange}
              options={getPlatFormTypeCollection()}
            />
            <Controls.Select
              name="smsType"
              label="예약 타입"
              value={values.smsType}
              onChange={handleInputChange}
              options={getSmsTypeCollection(values.platFormType)}
            />
            <Controls.Input
              variant="outlined"
              label="성함"
              type="text"
              name="customerStore"
              value={values.customerStore}
              onChange={handleInputChange}
            />
            <Controls.Input
              variant="outlined"
              label="업체명"
              type="text"
              name="storeName"
              placeholder="팔용 타임"
              value={values.storeName}
              onChange={handleInputChange}
            />
            <Controls.Input
              variant="outlined"
              label="객실 타입"
              type="text"
              name="roomType"
              placeholder="일반B(넷플릭스)"
              value={values.roomType}
              onChange={handleInputChange}
            />
            <Controls.Input
              variant="outlined"
              label="입실일시"
              type="text"
              name="entranceHour"
              placeholder="ex) 18:00"
              value={values.entranceHour}
              onChange={handleInputChange}
            />
            <Controls.Input
              variant="outlined"
              label="퇴실일시"
              type="text"
              name="exitHour"
              placeholder="ex) 12:00"
              value={values.exitHour}
              onChange={handleInputChange}
            />
            <Controls.Input
              variant="outlined"
              label="휴대폰"
              type="text"
              name="customerPhone"
              value={values.customerPhone}
              onChange={handleInputChange}
            />
            <div>
              <Controls.Button type="submit" text="문자발송" />
            </div>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
