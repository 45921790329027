import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  secondary: {
    backgroundColor: '#3c44b126',
    '& .MuiButton-label': {
      color: '#333996',
    },
  },
  primary: {
    backgroundColor: '#f8324526',
    '& .MuiButton-label': {
      color: '#f83245',
    },
  },
}));

export default function ActionButton(props) {
  const { color, children, onClick, component, to } = props;
  const classes = useStyles();

  return (
    <Button
      className={`${classes.root} ${classes[color]}`}
      component={component}
      onClick={onClick}
      to={to}
    >
      {children}
    </Button>
  );
}
