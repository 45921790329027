import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useForm, Form } from '../common/useForm';
import Controls from '../common/FormType/Controls';
import Notification from '../common/Notification';
import ConfirmDialog from '../common/ConfirmDialog';
import * as customerService from '../../lib/api/FormService/CustomerService';
import Axios from 'axios';
const genderItems = [
  { id: 'male', title: 'Male' },
  { id: 'female', title: 'Female' },
  { id: 'other', title: 'Other' },
];

const initialValues = {
  id: 0,
  customerName: '',
  customerPhone: '',
  customerEmail: '',
  customerStore: '',
  location: '',
  customerPhoneTime: '',
  customerStatus: null,
  // customerIncomePage: '',
  // departmentId: '',
  // gender: 'male',
  // hireDate: new Date().toISOString(),
  // isPermanent: false,
};

export default function MemoForm(props) {
  const { recordForEdit, addOrEdit, setOpenPopup } = props;

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialValues);

  const handleSubmit = async (e) => {
    e.preventDefault();
    addOrEdit(values, resetForm);
    setOpenPopup(false);
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container alignContent="center">
        <Controls.Input
          id="outlined-multiline-static"
          label="상담메모"
          multiline
          rows={15}
          variant="outlined"
          name="customerMemo"
          onChange={handleInputChange}
          value={values.customerMemo}
          style={{ minWidth: '800px' }}
        />
        <div>
          <Controls.Button type="submit" text="제출" />
        </div>
      </Grid>
    </Form>
  );
}
