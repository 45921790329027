import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../common/FormType/Controls";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import styled from "styled-components";

const DeleteBtn = styled.div`
  cursor: pointer;
`;

const QuotationAddFormData = ({
  values,
  index,
  setValues,
  arrays,
  onModifyArrays,
}) => {
  const [localValue, setLocalvalue] = useState("");
  const [startComponent, setStartcomponent] = useState(false);

  const onclick = () => {
    const newarr = arrays.filter((item, localindex) => {
      return localindex !== index;
    });
    setValues(newarr);
  };

  const changeLocalValue = useCallback(
    (e) => {
      setStartcomponent(true);
      const { name, value } = e.target;
      setLocalvalue({
        ...localValue,
        [name]: value,
      });
    },
    [localValue]
  );

  useEffect(() => {
    const { itemName, itemUnit, itemQuantity, itemUnitPrice, itemOther } =
      values;
    setLocalvalue({
      itemName: itemName,
      itemUnit: itemUnit,
      itemQuantity: itemQuantity,
      itemUnitPrice: itemUnitPrice,
      itemOther: itemOther,
    });
  }, [values]);

  useEffect(() => {
    if (startComponent) {
      setStartcomponent(false);
      onModifyArrays(localValue, index);
    }
  }, [localValue]);

  return (
    <>
      <Grid item xs={3}>
        <Controls.Input
          variant="outlined"
          label="품목"
          name="itemName"
          value={localValue.itemName}
          onChange={changeLocalValue}
        />
      </Grid>
      <Grid item xs={2}>
        <Controls.Input
          variant="outlined"
          label="단위"
          name="itemUnit"
          value={localValue.itemUnit}
          onChange={changeLocalValue}
        />
      </Grid>
      <Grid item xs={2}>
        <Controls.Input
          variant="outlined"
          label="수량"
          type="number"
          name="itemQuantity"
          value={localValue.itemQuantity}
          onChange={changeLocalValue}
        />
      </Grid>
      <Grid item xs={2}>
        <Controls.Input
          variant="outlined"
          label="단가"
          type="number"
          name="itemUnitPrice"
          value={localValue.itemUnitPrice}
          onChange={changeLocalValue}
        />
      </Grid>
      <Grid item xs={2}>
        <Controls.Input
          variant="outlined"
          label="비고"
          name="itemOther"
          value={localValue.itemOther}
          onChange={changeLocalValue}
        />
      </Grid>
      <Grid alignItems="center" container xs={1} styled={{ cursor: "pointer" }}>
        <DeleteBtn onClick={onclick}>
          <ClearOutlinedIcon />
        </DeleteBtn>
      </Grid>
    </>
  );
};

export default React.memo(QuotationAddFormData);
