import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useForm, Form } from '../common/useForm';
import Controls from '../common/FormType/Controls';
import * as customerService from '../../lib/api/FormService/CustomerService';
import Axios from 'axios';
const initialValues = {
  id: 0,
  chatBotCustomerName: '',
  // customerPhone: '',
  // customerEmail: '',
  // customerStore: '',
  // location: '',
  // customerIncomePage: '',
};

export default function ChatBotForm(props) {
  const { addOrEdit, recordForEdit } = props;

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialValues);

  const handleSubmit = (e) => {
    e.preventDefault();

    const fetchData = async () => {
      try {
        const response = await Axios.get(
          `https://api.channel.io/open/v3/users/@${values.chatBotCustomerName}`,
          {
            headers: {
              'X-Access-Key': '601ccc4571ecd8f51a6d',
              'X-Access-Secret': '33f17785ae71e493c9d1630093188e18',
            },
          }
        );
        if (response) {
          addOrEdit(
            {
              customerName: response.data.user.profile.name,
              customerPhone:
                '0' + response.data.user.profile.mobileNumber.substring(3),
              customerEmail: response.data.user.profile.email,
              customerStore: response.data.user.profile.HotelName,
              location: response.data.user.city,
              // customerIncomePage: response.data.user.profile.referrer.substring(
              //   0,
              //   23
              // ),
              customerId: response.data.user.memberId,
            },
            resetForm
          );
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container alignContent="center">
        <Grid item xs={12}>
          <Controls.Input
            variant="outlined"
            label="챗봇 고객"
            name="chatBotCustomerName"
            value={values.chatBotCustomerName}
            onChange={handleInputChange}
          />
          <div>
            <Controls.Button type="submit" text="Submit" />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
}
