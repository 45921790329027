import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, Divider } from "@material-ui/core";
import { useForm, Form } from "../common/useForm";
import Controls from "../common/FormType/Controls";
import * as customerService from "../../lib/api/FormService/CustomerService";
import QuotationSalesForm from "../Quotation/QuotationSalesForm";
import QuotationSubForm from "../Quotation/QuotationSubForm";
import QuotationEmailForm from "../Quotation/QutationEmailForm";
import QuotationOrderForm from "../Quotation/QuotationOrderForm";

const initialValues = {
  quotationId: "4",
  // departmentId: '',
  // gender: 'male',
  // hireDate: new Date().toISOString(),
  // isPermanent: false,
};

export default function OrderQuotationForm(props) {
  const { addOrEdit, recordForEdit, setOpenPopup, setRecords } = props;
  const { user } = useSelector(({ user }) => ({ user: user.user }));

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  // const [quotationNumPrice, setQuotationNumPrice] = useState({
  //   kioskAmount: "",
  //   kioskPrice: "",
  // });

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (validate()) {
    //   addOrEdit(values, resetForm);
    // }
  };

  // useEffect(() => {
  //   if (recordForEdit != null)
  //     setValues({
  //       ...recordForEdit,
  //     });
  // }, [recordForEdit]);
  // console.log(quotationNumPrice);
  return (
    <>
      <QuotationOrderForm recordForEdit={recordForEdit} user={user} />
      <Divider />
      <h2>메일 발송</h2>
      <QuotationEmailForm
        estimate={true}
        recordForEdit={recordForEdit}
        quotationType={values.quotationId}
        setOpenPopup={setOpenPopup}
        setRecords={setRecords}
        user={user}
      />
    </>
  );
}
