import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { FaMailBulk } from "react-icons/fa";
import PageHeader from "../../components/PageHeader/PageHeader";
import useTable from "../../components/common/useTable";
import * as customerService from "../../lib/api/FormService/CustomerService";
import Controls from "../../components/common/FormType/Controls";
import Popup from "../../components/common/Popup";
import EditorForm from "../../components/auth/EditorForm";
import AuthorityForm from "../../components/auth/AuthorityForm";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "40%",
  },
  newButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  tblCell: {
    textAlign: "center",
  },
}));

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* align children centered vertically */
  align-items: center; /* align children centered horizontally */
`;

const CustomerMain = () => {
  const { user } = useSelector(({ user }) => ({ user: user.user }));

  const headCells = [
    { id: "userRegister", label: "가입날짜", userauthority: 1 },
    { id: "username", label: "유저 이름", userauthority: 1 },
    {
      id: "userPhone",
      label: "휴대폰",
      disableSorting: true,
      userauthority: 1,
    },
    {
      id: "userEmail",
      label: "이메일",
      disableSorting: true,
      userauthority: 1,
    },
    { id: "authorize", label: "권한", disableSorting: true, userauthority: 1 },
    {
      id: "userBiz",
      label: "명함",
      disableSorting: true,
      userauthority: 1,
    },
  ];

  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState([]);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [uploadUserBizCard, setUploadUserBizCard] = useState(false);
  const [uploadUserAuthority, setUploadUserAuthority] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await customerService.getUsers();
        setRecords(response);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);

  const handleSearch = (e) => {
    const {
      target: { value },
    } = e;
    return value;
  };

  const handleKeyPress = (e) => {
    const value = handleSearch(e);
    if (e.key === "Enter") {
      setFilterFn({
        fn: (items) => {
          if (value == "") return items;
          return items.filter(
            (x) =>
              x.createdAt?.toLowerCase().includes(value) ||
              x.useremail?.toLowerCase().includes(value) ||
              x.username?.toLowerCase().includes(value) ||
              x.userphone?.toLowerCase().includes(value)
          );
        },
      });
    }
  };

  const UploadUserBizCard = (item) => {
    setRecordForEdit(item);
    setUploadUserBizCard(true);
  };

  const UploadUserAuthority = (item) => {
    setRecordForEdit(item);
    setUploadUserAuthority(true);
  };

  const UserAuthority = (authority) => {
    switch (authority) {
      case 0:
        return "No";
      case 1:
        return "Yes";
      case 2:
        return "Admin";
      default:
        return "Unknow";
    }
  };

  return (
    <Wrapper>
      <PageHeader
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
        title="벤디트 유저 관리"
        subTitle="유지 관리 및 편집"
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="사용자 검색"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
            onKeyPress={handleKeyPress}
          />
          <div
            style={{
              width: "60%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          ></div>
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {records &&
              recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell className={classes.tblCell}>
                    {item.createdAt.slice(2, 10)}
                  </TableCell>
                  <TableCell className={classes.tblCell}>
                    {item.username}
                  </TableCell>
                  <TableCell className={classes.tblCell}>
                    {item.userphone}
                  </TableCell>
                  <TableCell className={classes.tblCell}>
                    {item.useremail}
                  </TableCell>
                  <TableCell className={classes.tblCell}>
                    {user.userauthority === 2 ? (
                      <>
                        {UserAuthority(item.userauthority)}
                        <Controls.ActionButton
                          color="secondary"
                          onClick={() => {
                            UploadUserAuthority(item);
                          }}
                        >
                          <FaMailBulk />
                        </Controls.ActionButton>{" "}
                      </>
                    ) : (
                      UserAuthority(item.userauthority)
                    )}
                  </TableCell>
                  {user?.userauthority > 0 && (
                    <TableCell className={classes.tblCell}>
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          UploadUserBizCard(item);
                        }}
                      >
                        <FaMailBulk />
                      </Controls.ActionButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
      <Popup
        title="명함 등록"
        openPopup={uploadUserBizCard}
        setOpenPopup={setUploadUserBizCard}
      >
        <EditorForm
          recordForEdit={recordForEdit}
          location={"edit"}
          setOpenPopup={setUploadUserBizCard}
        />
      </Popup>
      <Popup
        title="권한 부여 설정"
        openPopup={uploadUserAuthority}
        setOpenPopup={setUploadUserAuthority}
      >
        <AuthorityForm
          recordForEdit={recordForEdit}
          setUploadUserAuthority={setUploadUserAuthority}
        />
      </Popup>
    </Wrapper>
  );
};

export default CustomerMain;
