import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";

export function useForm(initialValues, validateOnChange = false, validate) {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  const handleInputNumberChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: parseInt(value),
    });
  };

  const resetForm = () => {
    setValues(initialValues);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleInputNumberChange,
    resetForm,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
  },
}));

export function Form(props) {
  const classes = useStyles();
  const { children, ...other } = props;
  return (
    <form className={classes.root} autoComplete="off" {...other}>
      {props.children}
    </form>
  );
}

export const useArrayForm = (
  initialValues,
  validateOnChange = false,
  validate
) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = (index) => (e) => {
    let newArr = [...values];
    switch (e.target.name) {
      case "item":
        newArr[index].item = e.target.value;
        break;
      case "account":
        newArr[index].account = e.target.value;
        break;
      case "standard":
        newArr[index].standard = e.target.value;
        break;
      case "price":
        newArr[index].price = e.target.value;
        break;
      case "salesprice":
        newArr[index].salesprice = e.target.value;
        break;
      case "other":
        newArr[index].other = e.target.value;
        break;
      default:
        break;
    }

    // newArr[index].[e.target.name] = e.target.value ????
    setValues(newArr);
  };

  const resetForm = () => {
    setValues(initialValues);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
};
