import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useForm, Form } from "../common/useForm";
import Controls from "../common/FormType/Controls";
import * as customerService from "../../lib/api/FormService/CustomerService";
import Notification from "../common/Notification";
import ConfirmDialog from "../common/ConfirmDialog";

export default function QuotationBizcardForm(props) {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
  });
  const { Id, setOpenPopup, setResult } = props;

  const initialValues = {
    fileName: "",
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const fileHandler = (e) => {
    const [file] = e.target.files;
    setValues({
      ...values,
      fileName: file,
    });
  };

  const onSubmit = async () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    const formData = await new FormData();
    formData.append("file", values.fileName);
    await formData.append("id", Id);
    await formData.append("fileName", values.fileName.name);
    const result = await customerService.sendUserImage(formData);
    if (result === "success") {
      setNotify({
        isOpen: true,
        message: "등록완료",
        type: "success",
      });
      setTimeout(() => {
        setOpenPopup(false);
        setResult(true);
      }, 1000);
    } else {
      setNotify({
        isOpen: true,
        message: "등록실패",
        type: "error",
      });
    }
  };
  return (
    <div className="container">
      <Form encType="multipart/form-data">
        <Grid container alignContent="center">
          <Grid item xs={9}>
            <Controls.Input
              type="file"
              name="fileName"
              onChange={fileHandler}
              files
            />

            {values?.fileName.name !== undefined && (
              <div>
                <h3 style={{ margin: "0" }}>명함 이름</h3>
                {values?.fileName.name}
              </div>
            )}
            <Notification notify={notify} setNotify={setNotify} />
            <Controls.Button
              text="등록"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: "사진등록",
                  onConfirm: () => {
                    onSubmit();
                  },
                });
              }}
            />
            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
