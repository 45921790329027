import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import styled from "styled-components";
import * as FaIcons from "react-icons/fa";

import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { logout } from "../../modules/user";
import Button from "../common/Button";

const Nav = styled.div`
  display: block;
  overflow: visible;
  background-color: #3f4b3b;
  box-shadow: 0px 0px 20px 2px #e6e6e6;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  color: black;
  height: 80px;
  display: flex;

  /* justify-content: space-around; */
  align-items: center;

  &:hover {
    transform: scale(1.05);
  }
`;

const SidebarNav = styled.nav`
  background: #353866;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  /* transition: 350ms; */
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const UserInfo = styled.div`
  font-weight: 800;
  margin-right: 1rem;
  color: white;
`;

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const Sidebar = ({ history }) => {
  const { user } = useSelector(({ user }) => ({ user: user.user }));
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const sidebarref = useRef(null);
  const handleClickOutside = (event) => {
    if (sidebarref.current && !sidebarref.current.contains(event.target)) {
      setSidebar(false);
    }
  };

  const dispatch = useDispatch();
  const onLogout = async () => {
    await dispatch(logout());
    window.location.reload();
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <Nav>
        <NavIcon to="#">
          <FaIcons.FaBars style={{ color: "white" }} onClick={showSidebar} />
        </NavIcon>
        {user && (
          <UserInfoContainer>
            <UserInfo>{user.username}</UserInfo>
            <Button onClick={onLogout}>로그아웃</Button>
          </UserInfoContainer>
        )}
      </Nav>
      <SidebarNav sidebar={sidebar} ref={sidebarref}>
        <SidebarWrap>
          <NavIcon to="#">
            <FaIcons.FaArrowLeft
              style={{ color: "white" }}
              onClick={showSidebar}
            />
          </NavIcon>
          {SidebarData.map((item, index) => {
            return <SubMenu item={item} key={index} />;
          })}
        </SidebarWrap>
      </SidebarNav>
    </>
  );
};

export default withRouter(Sidebar);
