import React, { useState } from "react";
import styled from "styled-components";
import Axios from "axios";
import palette from "../../lib/styles/palette";
import { useHistory } from "react-router-dom";
import Button from "../common/Button";
import { Grid } from "@material-ui/core";
import { useForm, Form } from "../common/useForm";
import Controls from "../common/FormType/Controls";

const AuthFormBlock = styled.div`
  h3 {
    margin: 0;
    color: ${palette.gray[8]};
    margin-bottom: 1rem;
  }
`;

/**
 * 스타일링된 input
 */
const StyledInput = styled.input`
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid ${palette.gray[5]};
  padding-bottom: 0.5rem;
  outline: none;
  width: 100%;
  &:focus {
    color: $oc-teal-7;
    border-bottom: 1px solid ${palette.gray[7]};
  }
  & + & {
    margin-top: 1rem;
  }
`;

const initialValues = {
  id: 0,
  contractName: "",
  commonMessage: "",
  commonExp: "",
  customerName: "",
  customerEmail: "",
  customerPhone: "",
};

const ContractTemplateForm = ({ templateDetail, templateDetailReciver }) => {
  let history = useHistory();
  // const [inputs, setInputs] = useState({
  //   contractName: '',
  //   commonMessage: '',
  //   commonExp: '',
  //   customerName: '',
  //   customerEmail: '',
  //   customerPhone: '',
  // });

  // const {
  //   contractName,
  //   commonMessage,
  //   commonExp,
  //   reciverName,
  //   reciverEmail,
  //   reciverPhone,
  // } = inputs;

  // const onChange = (e) => {
  //   const { value, name } = e.target;
  //   setInputs({
  //     ...inputs,
  //     [name]: value,
  //   });
  // };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fetchData = async () => {
      try {
        let receiverOption;

        if (templateDetailReciver?.length === 1) {
          receiverOption = {
            receiverList: [
              {
                signOrderNumber: 1,
                name: values.customerName,
                email: values.customerEmail,
                userPhone: values.customerPhone,
                userPhoneCode: "KR/+82",
                lang: "kr",
                expired_day: parseInt(values.commonExp),
                identityAuth: "string",
                message: values.commonMessage,
              },
            ],
          };
        } else {
          receiverOption = {
            receiverList: [
              {
                signOrderNumber: 1,
                name: "벤디트",
                email: "ad@vendit.co.kr",
                userPhone: "01043547006",
                userPhoneCode: "KR/+82",
                lang: "kr",
                expired_day: parseInt(values.commonExp),
              },
              {
                signOrderNumber: 2,
                name: values.customerName,
                email: values.customerEmail,
                userPhone: values.customerPhone,
                userPhoneCode: "KR/+82",
                lang: "kr",
                expired_day: parseInt(values.commonExp),
                identityAuth: "string",
              },
            ],
          };
        }

        const response = await Axios({
          url: "https://api.glosign.com/v1/template/send", // your url
          headers: { Authorization: "Bearer lxCL35e2L6SgEO3dvDpd1idW1" },
          method: "post",
          data: {
            templateId: templateDetail.id,
            contractName: values.contractName,
            commonMessage: values.commonMessage,
            commonExp: parseInt(values.commonExp),
            lang: "ko",
            certLang: "ko",
            emailFlag: true,
            mobileFlag: true,
            clientId: "ue8969793f8cf51d8e8eef98b90fdd449",
            contractList: [
              {
                signOrder: true,
                isReview: false,
                contractName: values.contractName,
                // preSigner: {
                //   coord: [
                //     {
                //       id: 'text_0',
                //       data: values.hireDate.substring(0, 4),
                //     },
                //     {
                //       id: 'text_1',
                //       data: values.hireDate.substring(5, 7),
                //     },
                //     {
                //       id: 'text_2',
                //       data: values.hireDate.substring(8, 10),
                //     },
                //   ],
                // },
                ...receiverOption,
              },
            ],
          },
        });
        history.push(`/contracts/${response.data.contractList[0].contractId}`);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container alignContent="center">
        <Grid item xs={6}>
          <Controls.Input
            variant="outlined"
            label="계약서명"
            name="contractName"
            value={values.contractName}
            onChange={handleInputChange}
            // error={errors.customerName}
          />
          <Controls.Input
            variant="outlined"
            label="공통메시지"
            name="commonMessage"
            value={values.commonMessage}
            onChange={handleInputChange}
            // error={errors.customerPhone}
          />
          <Controls.Input
            variant="outlined"
            label="만료일"
            name="commonExp"
            value={values.commonExp}
            onChange={handleInputChange}
            // error={errors.customerEmail}
          />
        </Grid>
        <Grid item xs={6}>
          <Controls.Input
            variant="outlined"
            label="계약자성함"
            name="customerName"
            value={values.customerName}
            onChange={handleInputChange}
            // error={errors.customerStore}
          />
          <Controls.Input
            variant="outlined"
            label="계약자메일"
            name="customerEmail"
            value={values.customerEmail}
            onChange={handleInputChange}
            // error={errors.location}
          />
          <Controls.Input
            variant="outlined"
            label="계약자 폰번호"
            name="customerPhone"
            value={values.customerPhone}
            onChange={handleInputChange}
          />

          <div>
            <Controls.Button type="submit" text="Submit" />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default ContractTemplateForm;
