import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import Axios from "axios";
import { Form, useArrayForm } from "../common/useForm";
import Controls from "../common/FormType/Controls";
import Notification from "../common/Notification";
import ConfirmDialog from "../common/ConfirmDialog";
import * as orderingCompanyService from "../../lib/api/FormService/OrderingCompanyService";

const initialValues = [];

export default function RegisterOrderItem(props) {
  const { user } = useSelector(({ user }) => ({ user: user.user }));
  const { addOrEdit, recordForEdit, setRecords, setOpenPopup } = props;
  const [customerCallPhone, setCustomerCallPhone] = useState([]);
  const [customerCallPhoneInput, setCustomerCallPhoneInput] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useArrayForm(initialValues);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = {};

    for (let idx in values) {
      if (values[idx].item === "") {
        setNotify({
          isOpen: true,
          message: `품목${Number(idx) + 1} 이 비어있습니다`,
          type: "warning",
        });
        return;
      }
    }
    console.log(values);
    const chanteRest = JSON.stringify(values);

    result.id = recordForEdit.id;
    result.companyitem = chanteRest;
    await addOrEdit(result);
    setOpenPopup(false);
  };

  useEffect(() => {
    if (recordForEdit != null) {
      console.log(recordForEdit);
      const { id, companyitem } = recordForEdit;
      let changeParse = [];
      if (companyitem !== "") {
        changeParse = JSON.parse(companyitem);
      }

      setValues(changeParse);
    }
  }, [recordForEdit]);

  const addItemInput = () => {
    setValues((prev) => {
      let result = [...prev];
      result.push({ item: "", price: "" });

      return result;
    });
  };

  const deleteInput = () => {
    setValues((prev) => {
      let result = [...prev];
      result.pop();

      return result;
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      {values.map((item, index) => (
        <Grid container style={{ minWidth: "800px" }} key={index}>
          <Notification notify={notify} setNotify={setNotify} />
          <Grid item xs={2}>
            <Controls.Input
              variant="outlined"
              label={`품목${index + 1}`}
              name="item"
              value={values[index]?.item}
              onChange={handleInputChange(index)}
            />
          </Grid>
          <Grid item xs={2}>
            <Controls.Input
              variant="outlined"
              label={`거래처 품목${index + 1}`}
              name="account"
              value={values[index]?.account}
              onChange={handleInputChange(index)}
            />
          </Grid>
          <Grid item xs={2}>
            <Controls.Input
              variant="outlined"
              label={`규격${index + 1}`}
              name="standard"
              value={values[index]?.standard}
              onChange={handleInputChange(index)}
            />
          </Grid>
          <Grid item xs={2}>
            <Controls.Input
              variant="outlined"
              label={`매입가${index + 1}`}
              type="number"
              name="price"
              value={values[index]?.price}
              onChange={handleInputChange(index)}
            />
          </Grid>
          <Grid item xs={2}>
            <Controls.Input
              variant="outlined"
              label={`판매가${index + 1}`}
              type="number"
              name="salesprice"
              value={values[index]?.salesprice}
              onChange={handleInputChange(index)}
            />
          </Grid>
          <Grid item xs={2}>
            <Controls.Input
              variant="outlined"
              label={`비고${index + 1}`}
              name="other"
              value={values[index]?.other}
              onChange={handleInputChange(index)}
            />
          </Grid>
        </Grid>
      ))}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Controls.Button
            type="button"
            text="인풋생성"
            onClick={addItemInput}
          />
          <Controls.Button
            type="button"
            text="인풋삭제"
            onClick={deleteInput}
          />
        </div>

        <div>
          <Controls.Button type="submit" text="저장" />
        </div>
      </div>
    </Form>
  );
}
