import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useForm, Form } from "../common/useForm";
import Controls from "../common/FormType/Controls";
import * as customerService from "../../lib/api/FormService/CustomerService";
import * as orderingCompanyService from "../../lib/api/FormService/OrderingCompanyService";
import Notification from "../common/Notification";
import ConfirmDialog from "../common/ConfirmDialog";
import Popup from "../../components/common/Popup";
import EditorForm from "../auth/EditorForm";

export default function QuotationEmailForm(props) {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [uploadUserBizCard, setUploadUserBizCard] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const [proposalChecked, setProposalChecked] = React.useState(false);
  const [keyTechcatalog, setKeyTechcatalog] = React.useState(false);
  const [sendSMSChecked, setSendSMSChecked] = React.useState(true);
  const [imgPath, setImagPath] = React.useState("");
  const [imgName, setImgName] = React.useState("");
  const {
    estimate,
    recordForEdit,
    setOpenPopup,
    setRecords,
    quotationNumPrice,
    user,
  } = props;
  const [result, setResult] = React.useState(false);
  const [reRender, setRerender] = React.useState(true);
  const [formRerender, setFormrerender] = React.useState(true);

  const initialValues = {
    // id: 0,
    // customerName: '',
    // customerPhone: '',
    // customerEmail: '',
    // customerStore: '',
    fileCollection: [],
    subject: "(주)벤디트 키오스크&객실관리프로그램 견적서 첨부드립니다.",
    body: `안녕하세요. (주)벤디트입니다.\n\n제품구매를 위해 ${
      estimate ? "발주서" : "견적서"
    } 첨부해서 발송드립니다.\n\n추가 문의사항이나 궁금하신 부분이 있으시다면 하단 명함 메일&문자&카카오톡&전화주시면 친절히 안내해드리겠습니다.\n\n감사합니다.`,
    // departmentId: '',
    // gender: 'male',
    // hireDate: new Date().toISOString(),
    // isPermanent: false,
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const fileHandler = (e) => {
    const { files } = e.target;
    setValues({
      ...values,
      fileCollection: [...values.fileCollection, files[0]],
    });
  };

  const handleCheckdChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleProposalCheckdChange = (event) => {
    setProposalChecked(event.target.checked);
  };

  const handleKeyTechCheckdChange = (event) => {
    setKeyTechcatalog(event.target.checked);
  };

  const handleSendSMSCheckdChange = (event) => {
    setSendSMSChecked(event.target.checked);
  };
  const getImagurl = async () => {
    const test = await customerService.GetImg(user.id);
    setImagPath(test[0].config.url);
    setImgName(test[1].data.filename);
  };

  useEffect(() => {
    getImagurl();
    if (recordForEdit != null)
      setValues({
        ...values,
        ...recordForEdit,
      });
  }, []);

  if (result) {
    getImagurl();
    setRerender(false);
    setTimeout(() => {
      setRerender(true);
    }, 3000);
    setResult(false);
  }

  const onSubmit = async () => {
    setFormrerender(false);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    const formData = await new FormData();
    values.fileCollection.forEach((file) => {
      formData.append("fileCollection", file);
    });

    // filepath - db에서 파일 경로를 받아와서 백앤드에서 쉽게 등록하기 위해서 받아옴
    try {
      const defultBizCard = await customerService.getDefaultBizCard();
      await formData.append("defaultBizCardName", defultBizCard.filename);
      await formData.append("defaultBizCardPath", defultBizCard.filepath);

      if (checked || proposalChecked || keyTechcatalog) {
        const checkedFilePath = checked
          ? await customerService.getFileTypePathInDB({
              filetype: "제품소개서 발송",
            })
          : "";
        const proposalFilePath = proposalChecked
          ? await customerService.getFileTypePathInDB({
              filetype: "제안서 발송",
            })
          : "";
        const keyTechcatalogPath = keyTechcatalog
          ? await customerService.getFileTypePathInDB({
              filetype: "키텍카탈로그 발송",
            })
          : "";

        if (checkedFilePath !== null) {
          await formData.append("productFileName", checkedFilePath.filename);
          await formData.append("productFilePath", checkedFilePath.filepath);
        }

        if (proposalFilePath !== null) {
          await formData.append("proposalFileName", proposalFilePath.filename);
          await formData.append("proposalFilePath", proposalFilePath.filepath);
        }

        if (keyTechcatalogPath !== null) {
          await formData.append("keytechFileName", keyTechcatalogPath.filename);
          await formData.append("keytechFilePath", keyTechcatalogPath.filepath);
        }
      }

      await formData.append("customerInfoId", values.id);
      await formData.append("customerName", values.customerName);
      await formData.append("customerPhone", values.customerPhone);
      await formData.append("customerStore", values.customerStore);
      await formData.append("customerEmail", values.customerEmail);
      await formData.append("subject", values.subject);
      await formData.append("body", values.body);
      await formData.append("quotationType", 10);
      await formData.append("kioskAmount", quotationNumPrice?.kioskAmount);
      await formData.append("kioskPrice", quotationNumPrice?.kioskPrice);
      await formData.append("username", user.username);
      await formData.append("userId", user.id);
      await formData.append("checked", checked);
      await formData.append("proposalChecked", proposalChecked);
      await formData.append("keyTechcatalog", keyTechcatalog);
      await formData.append("sendSMSChecked", sendSMSChecked);
      const result = await customerService.sendEmail(formData);
      await customerService.updateEmployee({
        id: values.id,
        customerStatus: "견적서발송",
      });

      setRecords(await customerService.getAllEmployees());
      if (result.success === true) {
        setFormrerender(true);
        deletealert();
        setNotify({
          isOpen: true,
          message: "메일발송완료",
          type: "success",
        });
        setTimeout(() => {
          setOpenPopup(false);
        }, 1000);
      } else if (result.error === true) {
        setFormrerender(true);
        deletealert();
        setNotify({
          isOpen: true,
          message: "Error!",
          type: "error",
        });
      }
    } catch (err) {
      setFormrerender(true);
      console.log(err);
    }
  };

  const deletealert = () => {
    setTimeout(() => {
      setNotify({
        isOpen: false,
        message: "",
        type: "",
      });
    }, 1500);
  };

  const phoneStandardNumber = (value) => {
    if (!value) {
      return "";
    }

    value = value.replace(/[^0-9]/g, "");

    let result = [];
    let restNumber = "";

    // 지역번호와 나머지 번호로 나누기
    if (value.startsWith("02")) {
      // 서울 02 지역번호
      result.push(value.substr(0, 2));
      restNumber = value.substring(2);
    } else if (value.startsWith("1")) {
      // 지역 번호가 없는 경우
      // 1xxx-yyyy
      restNumber = value;
    } else {
      // 나머지 3자리 지역번호
      // 0xx-yyyy-zzzz
      result.push(value.substr(0, 3));
      restNumber = value.substring(3);
    }

    if (restNumber.length === 7) {
      // 7자리만 남았을 때는 xxx-yyyy
      result.push(restNumber.substring(0, 3));
      result.push(restNumber.substring(3));
    } else {
      result.push(restNumber.substring(0, 4));
      result.push(restNumber.substring(4));
    }

    return result.filter((val) => val).join("-");
  };

  return (
    <div className="container">
      {formRerender ? (
        <Form encType="multipart/form-data">
          <Grid container alignContent="center">
            <Grid item xs={6}>
              <Controls.Input
                variant="outlined"
                label="고객명"
                type="text"
                name="customerName"
                onChange={handleInputChange}
                value={values.customerName}
              />
              {/* <Controls.Select
              label="휴대폰"
              name="customerPhonetest"
              value={values.customerPhonetest}
              onChange={handleInputChange}
              options={[
                {
                  id: values.customerPhone,
                  title: values.customerPhone,
                },
                {
                  id: values.customerPhoneTwo,
                  title: values.customerPhoneTwo,
                },
              ]}
            /> */}
              <Controls.Input
                variant="outlined"
                label="휴대폰"
                type="text"
                name="customerPhone"
                value={phoneStandardNumber(values.customerPhone)}
                onChange={handleInputChange}
              />
              <Controls.Input
                variant="outlined"
                label="업체명"
                type="text"
                name="customerStore"
                value={values.customerStore}
                onChange={handleInputChange}
              />
              <Controls.Input
                variant="outlined"
                label="이메일"
                type="email"
                name="customerEmail"
                onChange={handleInputChange}
                value={values.customerEmail}
              />
              {reRender ? (
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  style={{ marginLeft: "2px" }}
                >
                  <Grid item xs={4}>
                    {
                      // 이미지 가져오기
                      <img
                        src={imgPath}
                        alt=""
                        style={{ width: "100px", border: "1px solid black" }}
                      />
                    }
                  </Grid>
                  <Grid item xs={4}>
                    <Controls.Input
                      disabled
                      multiline
                      rows={3}
                      value={imgName}
                      style={{ width: "140%" }}
                    />
                    <Controls.Button
                      style={{
                        width: "140%",
                        marginLeft: "7px",
                        marginTop: "10px",
                      }}
                      text="명함 등록"
                      onClick={() => {
                        setUploadUserBizCard(true);
                      }}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Skeleton
                  sx={{ bgcolor: "dodgerblue" }}
                  height={175}
                  width={350}
                  style={{ marginLeft: "9px", marginTop: "10px" }}
                  variant="rectangular"
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <Controls.Input
                variant="outlined"
                label="메일제목"
                type="text"
                name="subject"
                value={values.subject}
                onChange={handleInputChange}
              />
              <Controls.Input
                id="outlined-multiline-static"
                label="메일 내용"
                multiline
                rows={10}
                variant="outlined"
                name="body"
                onChange={handleInputChange}
                value={values.body}
              />
              <Controls.Input
                type="file"
                name="fileCollection"
                onChange={fileHandler}
                multiple
                files
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sendSMSChecked}
                    onChange={handleSendSMSCheckdChange}
                    color="primary"
                  />
                }
                label="문자 발송"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleCheckdChange}
                    color="primary"
                  />
                }
                label="제품소개서 발송"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={proposalChecked}
                    onChange={handleProposalCheckdChange}
                    color="primary"
                  />
                }
                label="제안서 발송"
              />
              <FormControlLabel
                style={{ width: "100%" }}
                control={
                  <Checkbox
                    checked={keyTechcatalog}
                    onChange={handleKeyTechCheckdChange}
                    color="primary"
                  />
                }
                label="키텍카탈로그 발송"
              />
              {values.fileCollection.length > 0 && (
                <div>
                  <h3 style={{ margin: "0" }}>파일리스트(최대 3개)</h3>
                  {values.fileCollection.map((item) => (
                    <div>{item.name}</div>
                  ))}
                </div>
              )}
              {/* <Controls.Input type="file" onChange={fileHandlerTwo} />
            <Controls.Input type="file" onChange={fileHandlerThree} /> */}
              <Notification notify={notify} setNotify={setNotify} />
              <Controls.Button
                text="메일발송"
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: "메일발송",

                    onConfirm: () => {
                      onSubmit();
                    },
                  });
                }}
              />
              <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
              />
            </Grid>
          </Grid>
        </Form>
      ) : (
        <Skeleton
          sx={{ bgcolor: "dodgerblue" }}
          height={450}
          width={850}
          style={{ marginLeft: "9px", marginTop: "10px" }}
          variant="rectangular"
        />
      )}
      <Popup
        title="명함 등록"
        openPopup={uploadUserBizCard}
        setOpenPopup={setUploadUserBizCard}
      >
        <EditorForm
          location={""}
          setOpenPopup={setUploadUserBizCard}
          setResult={setResult}
        />
      </Popup>
    </div>
  );
}
