import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import Axios from "axios";
import { useForm, Form } from "../common/useForm";
import Controls from "../common/FormType/Controls";
import Notification from "../common/Notification";
import ConfirmDialog from "../common/ConfirmDialog";
import * as orderingCompanyService from "../../lib/api/FormService/OrderingCompanyService";

const initialValues = {
  id: 0,
  companyName: "",
  companyRegisterNumber: "",
  representativeName: "",
  companyPhone: "",
  companyFax: "",
  companyLocation: "",
  customerName: "",
  customerPhone: "",
  customerPosition: "",
  customerEmail: "",
  companyitem: "[]",
  companyStatus: null,
  userId: "",
};

export default function RegisterForm(props) {
  const { user } = useSelector(({ user }) => ({ user: user.user }));
  const { addOrEdit, recordForEdit, setRecords, setOpenPopup } = props;
  const [customerCallPhone, setCustomerCallPhone] = useState([]);
  const [customerCallPhoneInput, setCustomerCallPhoneInput] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (values.id === 0) {
      values.userId = user.id;
      const result = await addOrEdit(values, resetForm);
      if (result === "denied") {
        setNotify({
          isOpen: true,
          message: "중복된 거래처",
          type: "error",
        });
      } else {
        setOpenPopup(false);
      }
    } else {
      await addOrEdit(values, resetForm);
      setOpenPopup(false);
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    await orderingCompanyService.deleteCompany(id);
    setRecords(await orderingCompanyService.getAllCompany());
    setNotify({
      isOpen: true,
      message: "고객 삭제 완료",
      type: "error",
    });
    setOpenPopup(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container alignContent="center">
        <Grid item xs={6}>
          <h3>기본정보</h3>
          <Controls.Input
            variant="outlined"
            label="거래처명"
            name="companyName"
            value={values.companyName}
            onChange={handleInputChange}
            // error={errors.customerStore}
          />
          <Controls.Input
            variant="outlined"
            label="사업자번호"
            name="companyRegisterNumber"
            value={values.companyRegisterNumber}
            onChange={handleInputChange}
            // error={errors.customerName}
          />
          <Controls.Input
            variant="outlined"
            label="대표자성함"
            name="representativeName"
            value={values.representativeName}
            onChange={handleInputChange}
            // error={errors.customerPhone}
          />
          <Controls.Input
            variant="outlined"
            label="회사전화번호"
            name="companyPhone"
            value={values.companyPhone}
            onChange={handleInputChange}
            // error={errors.customerPhone}
          />
          <Controls.Input
            variant="outlined"
            label="회사Fax"
            name="companyFax"
            value={values.companyFax}
            onChange={handleInputChange}
            // error={errors.customerEmail}
          />
          <Controls.Input
            variant="outlined"
            label="회사주소"
            name="companyLocation"
            value={values.companyLocation}
            onChange={handleInputChange}
            // error={errors.customerEmail}
          />
          <Controls.Input
            variant="outlined"
            label="은행명"
            name="bankName"
            value={values.bankName}
            onChange={handleInputChange}
            // error={errors.customerEmail}
          />
          <Controls.Input
            variant="outlined"
            label="계좌번호"
            name="accountNum"
            value={values.accountNum}
            onChange={handleInputChange}
            // error={errors.customerEmail}
          />
        </Grid>
        <Grid item xs={6}>
          <h3>담당자 정보</h3>
          <Controls.Input
            variant="outlined"
            label="담당자명"
            name="customerName"
            value={values.customerName}
            onChange={handleInputChange}
            // error={errors.location}
          />
          <Controls.Input
            variant="outlined"
            label="담당자번호"
            name="customerPhone"
            value={values.customerPhone}
            onChange={handleInputChange}
            // error={errors.location}
          />
          <Controls.Input
            variant="outlined"
            label="담당자직급"
            name="customerPosition"
            value={values.customerPosition}
            onChange={handleInputChange}
            // error={errors.location}
          />
          <Controls.Input
            variant="outlined"
            label="담당자이메일"
            name="customerEmail"
            value={values.customerEmail}
            onChange={handleInputChange}
            // error={errors.location}
          />
          {/* {values.id !== 0 && (
            <Controls.Select
              name="customerStatus"
              label="진행상태"
              value={values.customerStatus}
              onChange={handleCustomerStatusInputChange}
              options={customerStatus}
            />
          )} */}
          <div>
            <Controls.Button type="submit" text="제출" />
            {values.id !== 0 && (
              <Controls.Button
                text="삭제"
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: "정말 삭제하시겠습니까??",
                    subTitle: "고객 삭제",
                    onConfirm: () => {
                      onDelete(values.id);
                    },
                  });
                }}
              />
            )}
          </div>
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </Grid>
      </Grid>
    </Form>
  );
}
