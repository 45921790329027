import React from "react";
import { Route, Redirect } from "react-router-dom";

import { useSelector } from "react-redux";

export default function DynamicRoute(props) {
  const { user } = useSelector(({ user }) => ({ user: user.user }));

  if (props.authenticated && !user) {
    return <Redirect to="/" />;
  } else if (props.guest && user) {
    return <Redirect to="/home" />;
  } else {
    return <Route component={props.component} {...props} />;
  }
}
