import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";

export const SidebarData = [
  {
    title: "Home",
    path: "/home",
    icon: <AiIcons.AiFillHome />,
  },
  {
    title: "고객 관리",
    path: "/customers",
    icon: <FaIcons.FaUserAlt />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "거래처 관리",
        path: "/orderingcompany",
        icon: <IoIcons.IoMdDocument />,
      },
      // {
      //   title: "호텔가 고객 관리",
      //   path: "/hotelgaCustomer",
      //   icon: <IoIcons.IoMdDocument />,
      // },
    ],
  },
  {
    title: "계약서 관리",
    path: "/contract",
    icon: <FaIcons.FaKey />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "서식 관리",
        path: "/contracttemplate",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "문서 관리",
    path: "/quotationmain",
    icon: <IoIcons.IoMdDocument />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "견적서 내역",
        path: "/quotationmain",
        icon: <IoIcons.IoMdDocument />,
      },
      {
        title: "발주서 내역",
        path: "/quotationorder",
        icon: <IoIcons.IoMdDocument />,
      },
      {
        title: "문서 파일 관리",
        path: "/quotationmanagement",
        icon: <IoIcons.IoMdDocument />,
      },
    ],

    // subNav: [
    //   {
    //     title: "견적서 생성",
    //     path: "/quotationadd",
    //     icon: <IoIcons.IoMdDocument />,
    //   },
    // ],
  },
  {
    title: "유저관리",
    path: "/register",
    icon: <FaIcons.FaUserAlt />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "회원가입",
        path: "/register",
      },
      {
        title: "유저 편집",
        path: "/edit",
      },
    ],
  },
  {
    title: "기타",
    path: "/otherfeatures",
    icon: <FaIcons.FaProjectDiagram />,
  },
];
