import React from "react";
import AuthTemplate from "../components/auth/AuthTemplate";
import RegisterForm from "../components/auth/RegisterForm";

const RegisterPage = () => {
  return (
    <AuthTemplate register={true}>
      <RegisterForm />
    </AuthTemplate>
  );
};

export default RegisterPage;
