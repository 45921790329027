import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import * as customerService from '../../lib/api/FormService/CustomerService';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    textAlign: 'center',
  },
});

export default function OrderDetailsTable({ recordForEdit }) {
  const classes = useStyles();
  const [customerQuotation, setCustomerQuotation] = useState([]);

  useEffect(() => {
    if (recordForEdit) {
      const fetchData = async () => {
        try {
          const response = await customerService.getCustomerQuotation(
            recordForEdit.id
          );
          setCustomerQuotation(response);
        } catch (e) {
          console.log(e);
        }
      };
      fetchData();
    }
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>발송일</TableCell>
            <TableCell align="center">키오스크 수량</TableCell>
            <TableCell align="center">가격</TableCell>
            <TableCell align="center">문서유형</TableCell>
            <TableCell align="center">담당자</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customerQuotation &&
            customerQuotation.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.createdAt.slice(0, 10)}
                </TableCell>
                <TableCell align="center">{`${row.kioskAmount}대`}</TableCell>
                <TableCell align="center">{`${(
                  row.kioskPrice * '1'
                ).toLocaleString('ko-KR')}원`}</TableCell>
                <TableCell align="center">
                  {row.quotationType === 1
                    ? '견적서 판매형'
                    : row.quotationType === 2
                    ? '견적서 구독형'
                    : row.quotationType === 3
                    ? '견적서 판매&구독'
                    : '기타'}
                </TableCell>
                <TableCell align="center">{row.username}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
