import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import styled from 'styled-components';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
} from '@material-ui/core';
import PageHeader from '../../components/PageHeader/PageHeader';
import useTable from '../../components/common/useTable';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* align children centered vertically */
  align-items: center; /* align children centered horizontally */
`;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: '75%',
  },
  newButton: {
    position: 'absolute',
    right: '10px',
  },
}));

const headCells = [
  { id: 'user_name', label: '성함', userauthority: 1 },
  { id: 'user_email', label: '이메일', userauthority: 1 },
  { id: 'user_phone', label: '핸드폰', disableSorting: true, userauthority: 1 },
  {
    id: 'signComplete',
    label: '사인여부',
    disableSorting: true,
    userauthority: 1,
  },
  {
    id: 'expiredAt',
    label: '서명만료일',
    disableSorting: true,
    userauthority: 1,
  },
  { id: 'url', label: 'URL', disableSorting: true, userauthority: 1 },
];

const ContractDetail = ({ match, history }) => {
  const classes = useStyles();
  const [contractDetail, setContractDetail] = useState({});
  const [records, setRecords] = useState([]);
  const { contractId } = match.params;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(
          `https://api.glosign.com/v1/contract?id=${contractId}`,
          {
            headers: {
              Authorization: 'Bearer lxCL35e2L6SgEO3dvDpd1idW1',
            },
          }
        );
        setContractDetail(response.data.contract);
        setRecords(response.data.contract.receiver);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);

  const downloaddata = () => {
    const fetchData = async () => {
      try {
        await Axios({
          url: `https://api.glosign.com/v1/docs/contract/download?id=${contractId}`, // your url
          headers: { Authorization: 'Bearer lxCL35e2L6SgEO3dvDpd1idW1' },
          method: 'GET',
          responseType: 'blob', // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Contract.zip'); // or any other extension
          document.body.appendChild(link);
          link.click();
        });
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  };

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(records, headCells);

  return (
    <Wrapper>
      {contractDetail && (
        <PageHeader
          icon={<AssignmentIcon fontSize="large" />}
          title={`계약명 : ${contractDetail.title}`}
          subTitle={`계약 진행상황 : ${contractDetail.contract_status}`}
        />
      )}
      <Paper className={classes.pageContent}>
        <TblContainer>
          <TblHead />
          <TableBody>
            {records &&
              records
                .sort((a, b) =>
                  a.signOrderNumber > b.signOrderNumber ? 1 : -1
                )
                .map((item) => (
                  <TableRow key={item.user_email}>
                    <TableCell>{item.user_name}</TableCell>
                    <TableCell>{item.user_email}</TableCell>
                    <TableCell>{item.user_phone}</TableCell>
                    <TableCell>
                      {item.signComplete === false ? '사인안함' : '사인완료'}
                    </TableCell>
                    <TableCell>{item.expiredAt.slice(0, 10)}</TableCell>
                    <TableCell>
                      {item.signComplete === false && (
                        <a href={item.url} target="_blank">
                          서명하기
                        </a>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
        {contractDetail?.contract_status === 'complete' && (
          <button onClick={downloaddata}>완료 계약서다운로드</button>
        )}
      </Paper>

      {/* <h1>서명 참여자 정보</h1>
      {contractDetailReciver &&
        contractDetailReciver.map((reciver) => (
          <>
            <div key={reciver.url}>
              <p>참여자 이름 : {reciver.user_name}</p>
              <p>참여자 메일 : {reciver.user_email}</p>
              <p>참여자 번호 : {reciver.user_phone}</p>
              <a href={reciver.url} target="_blank">
                서명보러가기
              </a>
              <p>
                참여자 사인여부 :{' '}
                {reciver.signComplete === false ? '사인안함' : '사인완료'}
              </p>
            </div>
          </>
        ))}
      <button onClick={downloaddata}>완료 계약서다운로드</button> */}
    </Wrapper>
  );
};

export default ContractDetail;
