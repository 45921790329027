import Axios from "axios";
import { FieldsOnCorrectTypeRule } from "graphql";

const CUSTOMER_URL = "/apis/customer";
const USER_URL = "/apis/auth";
const CONTRACT_URL = "/apis/contract";
const SENDEMAIL_URL = "/apis/sendemail";
const DOWNLOAD_URL = "/apis/uploads/";
const DOWNLOADS3_URL = "/apis/sendemail/download/";
const SENDSMS_URL = "/apis/otherfeatures/";
const GETIMG_URL = "/apis/requestimg/";
const QUOTATION_URL = "/apis/quotaionfiles";

export async function insertEmployee(data) {
  // await Axios.post('http://localhost:4000/api/customer/register', data)
  //   .then((res) => console.log(res.data))
  //   .catch((err) => console.log(err));
  try {
    // const check = await Axios.get(
    //   `http://localhost:3050/apis/customer/getcustomer/${data.customerPhone}`
    // );
    // if (check.data === null) {
    //   const response = await Axios.post(
    //     'http://localhost:3050/apis/customer/register',
    //     data
    //   );
    //   // console.log(response);
    // } else {
    //   window.alert('중복된 계정입니다.');
    // }
    const response = await Axios.post(CUSTOMER_URL + "/register", data);
  } catch (e) {
    console.log(e);
  }
}

export async function checkCustomer(data) {
  const check = await Axios.get(CUSTOMER_URL + `/getcustomer/${data}`);
  return check.data;
}

export async function getAllEmployees() {
  try {
    const data = await Axios.get(CUSTOMER_URL + "/getcustomer");
    return data.data;
  } catch (e) {
    console.error(e);
  }
}

export function updateEmployee(data) {
  Axios.patch(CUSTOMER_URL + "/edit", data)
    .then((res) => console.log(res.data))
    .catch((err) => console.log(err));
}

export async function deleteEmployee(id) {
  try {
    const data = await Axios.delete(CUSTOMER_URL + `/delete/${id}`);
  } catch (e) {
    console.error(e);
  }
}

export async function getCustomerQuotation(id) {
  try {
    const data = await Axios.get(CUSTOMER_URL + `/getquotation/${id}`);
    return data.data;
  } catch (e) {
    console.error(e);
  }
}

export async function getCustomerContract(id) {
  try {
    const data = await Axios.get(CUSTOMER_URL + `/getcontract/${id}`);
    return data.data;
  } catch (e) {
    console.error(e);
  }
}

export async function getPhoneNumber() {
  try {
    const data = await Axios.get(CUSTOMER_URL + "/getphonenumber");
    return data.data;
  } catch (e) {
    console.error(e);
  }
}

//계약서 API
export async function insertContract(data) {
  try {
    const response = await Axios.post(CONTRACT_URL + "/register", data);
    return response.data;
  } catch (e) {
    console.log(e);
  }
}

export async function getAllContract() {
  try {
    const response = await Axios.get(CONTRACT_URL + "/getcontract");
    return response.data;
  } catch (e) {
    console.log(e);
  }
}

//견적서 관련
export async function sendEmail(formData) {
  try {
    const result = await Axios({
      method: "post",
      url: SENDEMAIL_URL,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data;
  } catch (e) {
    console.log(e);
  }
}

export async function getQuotation() {
  try {
    const response = await Axios.get(SENDEMAIL_URL + "/getquotation");
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function getOrderQuotation() {
  try {
    const response = await Axios.get(SENDEMAIL_URL + "/getorderquotation");
    return response;
  } catch (e) {
    console.log(e);
  }
}

//업로드

export async function downloadQuotationFile(filename) {
  try {
    const result = await Axios({
      method: "get",
      url: DOWNLOAD_URL + filename,
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    });
    return result;
  } catch (e) {
    console.log(e);
  }
}

export async function downloadS3QuotationFile(filename) {
  try {
    const result = await Axios({
      method: "get",
      url: DOWNLOADS3_URL + filename,
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    });
    return result;
  } catch (e) {
    console.log(e);
  }
}

// 결제관련
export async function getCompletedCustomer() {
  try {
    const response = await Axios.get(CUSTOMER_URL + "/getcompletedcustomer");
    return response.data;
  } catch (e) {
    console.log(e);
  }
}

// 문자발송
export async function sendSMS(data) {
  try {
    const response = await Axios.post(SENDSMS_URL, data);
    return response.data;
  } catch (e) {
    console.log(e);
  }
}

//  = 유저 관련 =
export async function getUsers() {
  const userList = await Axios.get(`${USER_URL}/getUser`);
  return userList.data;
}

// 이미지 전송
export async function sendUserImage(formData) {
  try {
    const result = await Axios({
      method: "post",
      url: `${USER_URL}/updateImage`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data;
  } catch (e) {
    console.log(e);
  }
}

// 권한 부여
export async function updateUserAuthority(data) {
  const result = await Axios.post(`${USER_URL}/updateAuthority`, data);
  return result;
}

// 명함 이미지 가져오기
export async function GetImg(id) {
  const result = await Axios.all([
    Axios.get(GETIMG_URL + id),
    Axios.get(`${GETIMG_URL}filename/` + id),
  ]);
  return result;
}

// ============ 계약서 파일 관리 ============

// pdf view 구현문
// export async function getQuotationfilepdf(filetype) {
//   const quotationpdf = await Axios.post(`${QUOTATION_URL}/getpdf`, filetype);
//   const blob = new Blob([quotationpdf.data], { type: "application/pdf" });
//   const urls = window.URL.createObjectURL(blob);
//   return urls;
// }

// 파일이 DB에 존재하는지 Check하기 위한 함수
export async function getFileTypeInDB(filetype) {
  const quotationfiletype = await Axios.post(
    `${QUOTATION_URL}/getfilestype`,
    filetype
  );
  return quotationfiletype.data;
}

// Default 명함을 가져오기 위한 함수
export async function getDefaultBizCard() {
  const defaultBizCard = await Axios.get(`${QUOTATION_URL}/getDefaultBizCard`);
  return defaultBizCard.data;
}

// check된 pdf문서 path 가져오기 위한 함수
export async function getFileTypePathInDB(filetype) {
  const quotationfiletypepath = await Axios.post(
    `${QUOTATION_URL}/getfilestypepath`,
    filetype
  );
  return quotationfiletypepath.data;
}

//
export async function getQuotationfiles() {
  const quotationfiles = await Axios.get(`${QUOTATION_URL}/getfiles`);
  return quotationfiles.data;
}

// 파일을 수정하거나 추가할때 사용하는 함수
export async function addQuotationfiles(formData) {
  try {
    const result = await Axios({
      method: "post",
      url: QUOTATION_URL,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data;
  } catch (e) {
    console.log(e);
  }
}
