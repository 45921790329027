import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Axios from "axios";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { FaFileContract, FaFileAlt } from "react-icons/fa";
import PageHeader from "../../components/PageHeader/PageHeader";
import RegisterForm from "../../components/Customer/RegisterForm";
import useTable from "../../components/common/useTable";
import Controls from "../../components/common/FormType/Controls";
import * as customerService from "../../lib/api/FormService/CustomerService";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* align children centered vertically */
  align-items: center; /* align children centered horizontally */
`;

const headCells = [
  {
    id: "customerName",
    label: "고객명",
    disableSorting: true,
    userauthority: 1,
  },
  {
    id: "customerStore",
    label: "업체명",
    disableSorting: true,
    userauthority: 1,
  },
  { id: "username", label: "담당자", disableSorting: true, userauthority: 1 },
  { id: "to", label: "수령메일주소", disableSorting: true, userauthority: 1 },
  {
    id: "quotationType",
    label: "문서유형",
    disableSorting: true,
    userauthority: 1,
  },
  { id: "createdAt", label: "생성일", disableSorting: true, userauthority: 1 },
  {
    id: "pdfDownload",
    label: "PDF다운",
    disableSorting: true,
    userauthority: 1,
  },
];

const QuotationMain = ({ match }) => {
  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (match.url == "/quotationmain") {
          response = await customerService.getQuotation();
        } else {
          response = await customerService.getOrderQuotation();
        }

        const result = response.data.map((item) => {
          let filteredStatus;

          if (item.quotationType === 1) {
            filteredStatus = "견적서(판매형)";
          } else if (item.quotationType === 2) {
            filteredStatus = "견적서(구매형)";
          } else if (item.quotationType === 3) {
            filteredStatus = "견적서(구독&판매)";
          } else if (item.quotationType === 4) {
            filteredStatus = "발주서";
          }

          let filenameParse;
          filenameParse = JSON.parse(item.filename);

          return {
            ...item,
            quotationType: filteredStatus,
            filename: filenameParse,
          };
        });
        setRecords(result);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [match.url]);

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(records, headCells, filterFn);

  const handleSearch = (e) => {
    const { target } = e;
    setFilterFn({
      fn: (items) => {
        if (target.value == "") return items;
        return items.filter(
          (x) =>
            x.customerName?.toLowerCase().includes(target.value) ||
            x.customerStore?.toLowerCase().includes(target.value) ||
            x.username?.toLowerCase().includes(target.value) ||
            x.to?.toLowerCase().includes(target.value) ||
            x.quotationType?.toLowerCase().includes(target.value)
        );
      },
    });
  };

  // const addOrEdit = async (employee, resetForm) => {
  //   if (employee.id == 0) {
  //     await customerService.insertEmployee(employee);
  //   } else {
  //     await customerService.updateEmployee(employee);
  //   }
  //   resetForm();
  //   setRecordForEdit(null);
  //   setOpenPopup(false);
  //   setRecords(await customerService.getAllEmployees());
  //   setNotify({
  //     isOpen: true,
  //     message: '제출 완료',
  //     type: 'success',
  //   });
  // };

  // const openInPopup = (item) => {
  //   setRecordForEdit(item);
  //   setOpenPopup(true);
  // };

  // const onDelete = async (id) => {
  //   setConfirmDialog({
  //     ...confirmDialog,
  //     isOpen: false,
  //   });
  //   await customerService.deleteEmployee(id);
  //   setRecords(await customerService.getAllEmployees());
  //   setNotify({
  //     isOpen: true,
  //     message: 'Deleted Successfully',
  //     type: 'error',
  //   });
  // };

  const downloadFile = (filename) => {
    let encode = encodeURIComponent(filename);

    if (records) {
      // Axios({
      //   url: `http://3.36.6.18:3050/apis/uploads/${filename}`,
      //   method: 'get',
      //   responseType: 'arraybuffer',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Accept: 'application/pdf',
      //   },
      // })
      customerService
        .downloadS3QuotationFile(encode)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${filename}.pdf`); // or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <Wrapper>
      <PageHeader
        icon={<AssignmentIcon fontSize="large" />}
        title={
          match.url == "/quotationmain"
            ? "벤디트 견적서 관리"
            : "벤디트 발주서 관리"
        }
        subTitle={match.url == "/quotationmain" ? "견적서 관리" : "발주서 관리"}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="견적서 검색"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {records &&
              recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.customerName}</TableCell>
                  <TableCell>{item.customerStore}</TableCell>
                  <TableCell>{item.username}</TableCell>
                  <TableCell>{item.customerEmail}</TableCell>
                  <TableCell>{item.quotationType}</TableCell>
                  <TableCell>{item.createdAt.slice(0, 10)}</TableCell>
                  <TableCell>
                    {item.filename &&
                      item.filename.map((item) => (
                        <Controls.ActionButton
                          color="secondary"
                          onClick={() => {
                            downloadFile(item);
                          }}
                        >
                          <FaFileContract />
                        </Controls.ActionButton>
                      ))}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
    </Wrapper>
  );
};

export default QuotationMain;
