import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import ContractTemplateForm from '../../components/Contract/ContractTemplateForm';
import styled from 'styled-components';
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PageHeader from '../../components/PageHeader/PageHeader';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* align children centered vertically */
  align-items: center; /* align children centered horizontally */
`;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: '75%',
  },
  newButton: {
    position: 'absolute',
    right: '10px',
  },
}));

const ContractTemplateDetail = ({ match }) => {
  const classes = useStyles();
  const { id } = match.params;
  const [templateDetail, setTemplateDetail] = useState({});
  const [templateDetailReciver, setTemplateDetailReciver] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(
          `https://api.glosign.com/v1/template?id=${id}`,
          {
            headers: {
              Authorization: 'Bearer lxCL35e2L6SgEO3dvDpd1idW1',
            },
          }
        );
        setTemplateDetail(response.data);
        setTemplateDetailReciver(response.data.receiver);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);
  

  return (
    <Wrapper>
      {templateDetail && (
        <PageHeader
          icon={<AssignmentIcon fontSize="large" />}
          title={`서식명: ${templateDetail.title}`}
          subTitle="계약서 발송"
        />
      )}
      <Paper className={classes.pageContent}>
        <h1>서명 참여자 정보</h1>
        {templateDetailReciver &&
          templateDetailReciver.map((reciver, index) => (
            <div key={index}>
              <p>
                서명자 역할: {reciver.user} / 서명 순서 :
                {reciver.signOrderNumber}
              </p>
            </div>
          ))}
        {templateDetail && (
          <ContractTemplateForm
            templateDetail={templateDetail}
            templateDetailReciver={templateDetailReciver}
          />
        )}
      </Paper>
    </Wrapper>
  );
};

export default ContractTemplateDetail;
