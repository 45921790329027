import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Logo from "../lib/images/vendit-logo-black.png";

const HomeContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 90vh;
  font-family: sans-serif;
`;

const LogoImg = styled.img`
  width: 200px;
`;

const Home = () => {
  return (
    <HomeContainer>
      <LogoImg src={Logo} />

      <p>v2.4.4 03-25</p>
    </HomeContainer>
  );
};

export default Home;
