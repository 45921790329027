import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Axios from "axios";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PageHeader from "../../components/PageHeader/PageHeader";
import useTable from "../../components/common/useTable";
import Controls from "../../components/common/FormType/Controls";
import Button from "../../components/common/Button";
import * as customerService from "../../lib/api/FormService/CustomerService";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },
}));

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* align children centered vertically */
  align-items: center; /* align children centered horizontally */
`;

const headCells = [
  { id: "createdAt", label: "등록날짜", userauthority: 1 },
  { id: "customerStore", label: "업체명", userauthority: 1 },
  { id: "title", label: "계약서명", disableSorting: true, userauthority: 1 },
  {
    id: "templateName",
    label: "서식명",
    disableSorting: true,
    userauthority: 1,
  },
  {
    id: "contract_status",
    label: "진행상태",
    disableSorting: true,
    userauthority: 1,
  },
  { id: "username", label: "담당자", disableSorting: true, userauthority: 1 },
  {
    id: "contractId",
    label: "상세내역",
    disableSorting: true,
    userauthority: 1,
  },
];

const ContractMain = () => {
  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState([]);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(
          "https://api.glosign.com/v1/contract/list?from=20200120&to=20211230&clientid=ue8969793f8cf51d8e8eef98b90fdd449",
          {
            headers: {
              Authorization: "Bearer lxCL35e2L6SgEO3dvDpd1idW1",
            },
          }
        );

        // const result = response.data.contractList.map((item) => {
        //   let filteredStatus;

        //   if (item.contract_status === 'complete') {
        //     filteredStatus = '계약완료';
        //   } else {
        //     filteredStatus = '진행중';
        //   }

        //   return { ...item, contract_status: filteredStatus };
        // });

        const dbContractResponse = await customerService.getAllContract();
        const result = dbContractResponse.map((item) => {
          const filter = response.data.contractList.filter((filterItem) => {
            if (filterItem.contract_id === item.contractId) {
              return filterItem;
            }
          });
          if (filter[0].contract_id === item.contractId) {
            let filteredStatus;
            if (filter[0].contract_status === "complete") {
              filteredStatus = "계약완료";
            } else {
              filteredStatus = "진행중";
            }
            return {
              ...item,
              contract_status: filteredStatus,
              title: filter[0].title,
            };
          }
        });

        setRecords(result);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);

  const handleSearch = (e) => {
    const { target } = e;

    setFilterFn({
      fn: (items) => {
        if (target.value == "") return items;
        return items.filter(
          (x) =>
            x.title?.toLowerCase().includes(target.value) ||
            x.contract_status?.toLowerCase().includes(target.value)
        );
      },
    });
  };

  return (
    <Wrapper>
      <PageHeader
        icon={<AssignmentIcon fontSize="large" />}
        title="벤디트 계약 관리"
        subTitle="계약 세부 사항 조회"
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="고객 검색"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {records &&
              recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.createdAt.slice(0, 10)}</TableCell>
                  <TableCell>{item.customerStore}</TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.templateName}</TableCell>
                  <TableCell>{item.contract_status}</TableCell>
                  <TableCell>{item.username}</TableCell>
                  <TableCell>
                    <Button
                      to={`/contracts/${item.contractId}`}
                      style={{ background: "crimson" }}
                    >
                      상세내역
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
    </Wrapper>
  );
};

export default ContractMain;
