// import client from './client';

// // 로그인
// export const login = ({ useremail, password }) =>
//   client.post('http://3.36.6.18:3050/apis/auth/login', { useremail, password });

// // 회원가입
// export const register = ({ username, password, useremail, userphone }) =>
//   client.post('http://3.36.6.18:3050/apis/auth/register', {
//     username,
//     password,
//     useremail,
//     userphone,
//   });

// // 로그인 상태 확인
// export const check = () => client.get('http://3.36.6.18:3050/apis/auth/check');

// // 로그아웃
// export const logout = () =>
//   client.post('http://3.36.6.18:3050/apis/auth/logout');

import client from "./client";

// 로그인
export const login = ({ useremail, password }) =>
  client.post("/apis/auth/login", { useremail, password });

// 회원가입
export const register = ({ username, password, useremail, userphone }) => {
  const result = client.post("/apis/auth/register", {
    username,
    password,
    useremail,
    userphone,
  });
  return result;
};

// 로그인 상태 확인
export const check = () => client.get("/apis/auth/check");

// 로그아웃
export const logout = () => client.post("/apis/auth/logout");
