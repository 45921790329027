import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useForm, Form } from "../common/useForm";
import Controls from "../common/FormType/Controls";
import * as customerService from "../../lib/api/FormService/CustomerService";
import Notification from "../common/Notification";
import ConfirmDialog from "../common/ConfirmDialog";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function QuotationBizcardForm({ setOpenPopup }) {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
  });

  const initialValues = {
    fileName: "",
    fileType: "",
    fileRealFile: "",
  };

  const [orderStore, setOrderStore] = useState([
    {
      id: 0,
      fileType: "제품소개서 발송",
      realFileType: "application/pdf",
    },
    {
      id: 1,
      fileType: "제안서 발송",
      realFileType: "application/pdf",
    },
    {
      id: 2,
      fileType: "Default 명함",
      realFileType: "image",
    },
    {
      id: 3,
      fileType: "키텍카탈로그 발송",
      realFileType: "application/pdf",
    },
  ]);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfViewer, setPdfviewer] = useState(true);
  const [imgUrl, setImgurl] = useState(null);

  //                    변수 선언 절취선
  // ==========================================================
  //

  // 파일 change 함수
  const fileHandler = (e) => {
    const [file] = e.target.files;
    if (file.type === "application/pdf") {
      setImgurl(null);
      setPdfviewer(true);
    } else {
      const urls = URL.createObjectURL(file);
      setImgurl(urls);
      setPdfviewer(false);
    }
    setValues({
      ...values,
      fileRealFile: file,
    });
  };

  const setFileType = (values) => {
    switch (values) {
      case 1:
        return "제품소개서 발송";
      case 2:
        return "제안서 발송";
      case 3:
        return "Default 명함";
      case 4:
        return "키텍카탈로그 발송";
      default:
        return "none";
    }
  };

  // formData 전송 함수
  const onSubmit = async () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    const fileType = setFileType(values.fileType);
    if (
      (values.fileType - 1 <= 1 &&
        orderStore[values.fileType - 1].realFileType !==
          values.fileRealFile.type) ||
      (values.fileType - 1 === 2 &&
        values.fileRealFile.type.split("/")[0] !==
          orderStore[values.fileType - 1].realFileType)
    ) {
      setNotify({
        isOpen: true,
        message: `파일 유형이 맞지 않습니다! required : ${
          orderStore[values.fileType - 1].realFileType
        }`,
        type: "error",
      });
      return;
    }

    const formData = await new FormData();
    await formData.append("file", values.fileRealFile);
    await formData.append("filename", values.fileName);
    await formData.append("fileoriginalname", values.fileRealFile.name);
    await formData.append("filetype", fileType);
    const result = await customerService.addQuotationfiles(formData);
    if (result === "success") {
      setNotify({
        isOpen: true,
        message: "등록완료",
        type: "success",
      });
      setTimeout(() => {
        setOpenPopup(false);
      }, 1000);
    } else {
      setNotify({
        isOpen: true,
        message: "등록실패",
        type: "error",
      });
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleOrderDocsInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const orderSelectLoop = () => {
    const loop = orderStore.map((item, index) => {
      return { id: index + 1, title: item.fileType };
    });
    const result = [{ id: 0, title: "파일 유형을 선택해 주세요" }, ...loop];
    return result;
  };

  const exsitFileInDB = async () => {
    const filetype = setFileType(values.fileType);
    const { fileName, fileType, fileRealFile } = values;
    const newArr = await new Array([fileName], [fileType], [fileRealFile]);

    for (let i in newArr) {
      if (newArr[i] == null || newArr[i] == "") {
        setNotify({
          isOpen: true,
          message: "칸을 채워주세요",
          type: "error",
        });
        return;
      }
    }
    const result = await customerService.getFileTypeInDB({
      filetype: filetype,
    });
    const setTitle = (await (result === "exist"))
      ? "파일이 이미 존재합니다 \n편집 하시겠습니까?"
      : "등록 하시겠습니까?";
    console.log(setTitle);
    setConfirmDialog({
      isOpen: true,
      title: setTitle,
      onConfirm: () => {
        onSubmit();
      },
    });
  };

  return (
    <div className="container">
      <Form encType="multipart/form-data">
        <Grid container alignContent="center">
          <Grid item xs={9}>
            {pdfViewer ? (
              <Grid justifyContent="center">
                <Document
                  file={values.fileRealFile}
                  onLoadSuccess={onDocumentLoadSuccess}
                  style={{ width: "200px" }}
                >
                  <Page pageNumber={pageNumber} />
                </Document>
                <Grid container justifyContent="center" xs={11}>
                  <span
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                    }}
                    onClick={() =>
                      pageNumber > 1 ? setPageNumber(pageNumber - 1) : null
                    }
                  >
                    <ArrowBackIcon
                      style={{
                        cursor: "pointer",
                        marginRight: "10px",
                      }}
                    />
                  </span>
                  <span>
                    {pageNumber} / {numPages}
                  </span>
                  <span
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                    }}
                    onClick={() =>
                      pageNumber < numPages
                        ? setPageNumber(pageNumber + 1)
                        : null
                    }
                  >
                    <ArrowForwardIcon />
                  </span>
                </Grid>
              </Grid>
            ) : (
              <Grid container justifyContent="center">
                <img src={imgUrl} alt="" style={{ width: "80%" }} />
              </Grid>
            )}
          </Grid>
          <Grid item xs={3}>
            <Controls.Input
              variant="outlined"
              label="파일명"
              type="text"
              name="fileName"
              value={values.fileName}
              required={true}
              onChange={handleInputChange}
            />
            <Controls.ObjectSelect
              label="파일 유형 선택"
              name="fileType"
              value={values.fileType}
              onChange={handleOrderDocsInputChange}
              options={orderSelectLoop()}
            />
            <Controls.Input
              type="file"
              name="fileRealFile"
              onChange={fileHandler}
              required={true}
              files
            />
            <Notification notify={notify} setNotify={setNotify} />
            <Grid container justifyContent="flex-end" xs={10}>
              <Controls.Button
                text="등록"
                onClick={() => {
                  exsitFileInDB();
                }}
              />
            </Grid>
            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
