import React, { useState } from "react";
import Axios from "axios";
import {
  PDFDownloadLink,
  BlobProvider,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  pdf,
  Font,
} from "@react-pdf/renderer";
import font from "../../lib/styles/NanumGothic-Regular.ttf";
import OrderFormImage from "../../lib/images/newOrderForms.png";

Font.register({
  family: "Nanum Gothic",
  src: "https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Regular.ttf",
});

const fontOptions = {
  fontFamily: "Nanum Gothic",
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    width: "100%",
    height: "100%",
    // orientation: 'portrait',
  },
  view: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    padding: 0,
    backgroundColor: "white",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
    // margin: '10',
  },
  sectioncustomerName: {
    position: "absolute",
    ...fontOptions,
    top: 275,
    left: 135,
    width: "200px",
    // textAlign: "justify",
  },
  customerName: {
    ...fontOptions,
    fontSize: 8,
    fontWeight: "thin",
  },
  username: {
    position: "absolute",
    ...fontOptions,
    top: 212,
    left: 135,
    // fontWeight: 200,
    fontSize: 8,
  },
  userphone: {
    position: "absolute",
    ...fontOptions,
    top: 227,
    left: 135,
    fontSize: 8,
  },
  // 납품 관련
  sendingStore: {
    position: "absolute",
    ...fontOptions,
    top: 307,
    left: 135,
    fontSize: 8,
  },
  sectionsendingLocation: {
    position: "absolute",
    ...fontOptions,
    top: 324,
    left: 135,
    width: "170px",
  },
  sendingLocation: {
    ...fontOptions,
    fontSize: 8,
  },
  // ~ 납품 관련
  sectionKioskType: {
    position: "absolute",
    ...fontOptions,
    top: 380,
    left: 57,
    width: "80px",
    // textAlign: "justify",
  },
  kioskType: {
    ...fontOptions,
    fontSize: 8,
  },
  kioskTypeEA: {
    position: "absolute",
    ...fontOptions,
    top: 380,
    left: 156,
    fontSize: 8,
  },
  kioskAmount: {
    position: "absolute",
    ...fontOptions,
    top: 380,
    left: 222,
    fontSize: 8,
  },
  kioskPrice: {
    position: "absolute",
    ...fontOptions,
    top: 380,
    left: 250,
    fontSize: 8,
  },
  kioskAmountTotal: {
    position: "absolute",
    ...fontOptions,
    top: 380,
    left: 310,
    fontSize: 8,
  },
  kioskAmountTax: {
    position: "absolute",
    ...fontOptions,
    top: 380,
    left: 382,
    fontSize: 8,
  },
  kioskRemark: {
    position: "absolute",
    ...fontOptions,
    top: 380,
    left: 463,
    fontSize: 8,
  },
  totalPrice: {
    position: "absolute",
    ...fontOptions,
    top: 735,
    left: 310,
    fontSize: 8,
  },
  totalTax: {
    position: "absolute",
    ...fontOptions,
    top: 735,
    left: 382,
    fontSize: 8,
  },
  totalPriceATax: {
    position: "absolute",
    ...fontOptions,
    top: 735,
    left: 463,
    fontSize: 8,
  },
  aboveTotal: {
    position: "absolute",
    ...fontOptions,
    top: 735,
    left: 463,
    fontSize: 8,
  },
  // section: {
  //   position: "absolute",
  //   ...fontOptions,
  //   // top: 380,
  //   // left: 57,
  //   width: "80px",
  // },
  kioskTextStyle: {
    ...fontOptions,
    fontSize: 8,
    fontWeight: "bold",
  },

  // 작성일
  quotationDate: {
    position: "absolute",
    ...fontOptions,
    top: 290,
    left: 135,
    fontSize: 8,
  },
  // 기타사항
  sectionkioskAddMemo: {
    position: "absolute",
    ...fontOptions,
    top: 795,
    left: 58,
    width: "478px",
    // textAlign: "justify",
  },
  kioskAddMemo: {
    ...fontOptions,
    lineHeight: 1.1,
    fontSize: 8,
    // textAlign: "justify",
  },
});

export function PdfDocument({ data, userValue }) {
  let sum = 0;
  for (let i in data) {
    sum += data[i].itemQuantity * data[i].itemUnitPrice;
  }

  return (
    <Document>
      <Page style={styles.page} size="A4">
        <View style={styles.view}>
          <Image style={styles.image} src={OrderFormImage} alt="images" />
          <View style={styles.sectioncustomerName}>
            <Text style={styles.customerName}>{userValue.customerStore}</Text>
          </View>
          <Text style={styles.quotationDate}>
            {userValue.orderWritingDate.slice(0, 10)}
          </Text>
          <Text style={styles.sendingStore}>{userValue.sendingStore}</Text>
          <View style={styles.sectionsendingLocation}>
            <Text style={styles.sendingLocation}>
              {userValue.sendingLocation}
            </Text>
          </View>
          <Text style={styles.username}>{userValue.username}</Text>
          <Text style={styles.userphone}>{userValue.userphone}</Text>

          {data.map((informations, index) => {
            const leftIncrease = (leftCount) => {
              let leftSize;
              switch (leftCount) {
                case "SECTION":
                  leftSize = 57;
                  break;
                case "STANDARD":
                  leftSize = 156;
                  break;
                case "AMOUNT":
                  leftSize = 222;
                  break;
                case "ITEMPRICE":
                  leftSize = 250;
                  break;
                case "AMOUNTPRICE":
                  leftSize = 310;
                  break;
                case "PRICETAX":
                  leftSize = 382;
                  break;
                case "OTHER":
                  leftSize = 463;
                  break;
                default:
                  break;
              }

              const styleTop = {
                position: "absolute",
                ...fontOptions,
                top: 380 + index * 20,
                left: leftSize,
                width: "80px",
              };
              return styleTop;
            };

            return (
              <>
                <View style={leftIncrease("SECTION")}>
                  <Text style={styles.kioskTextStyle}>
                    {informations.itemName}
                  </Text>
                </View>

                <View style={leftIncrease("STANDARD")}>
                  <Text style={styles.kioskTextStyle}>
                    {informations.itemUnit}
                  </Text>
                </View>

                <View style={leftIncrease("AMOUNT")}>
                  <Text style={styles.kioskTextStyle}>
                    {informations.itemQuantity}
                  </Text>
                </View>

                <View style={leftIncrease("ITEMPRICE")}>
                  <Text style={styles.kioskTextStyle}>
                    {informations.itemUnitPrice &&
                      (informations.itemUnitPrice * "1").toLocaleString(
                        "ko-KR"
                      )}
                  </Text>
                </View>

                <View style={leftIncrease("AMOUNTPRICE")}>
                  <Text style={styles.kioskTextStyle}>
                    {informations.itemUnitPrice &&
                      (
                        informations.itemUnitPrice * informations.itemQuantity
                      ).toLocaleString("ko-KR")}
                  </Text>
                </View>

                <View style={leftIncrease("PRICETAX")}>
                  <Text style={styles.kioskTextStyle}>
                    {informations.itemUnitPrice &&
                      Math.round(
                        informations.itemUnitPrice *
                          informations.itemQuantity *
                          "0.1"
                      ).toLocaleString("ko-KR")}
                  </Text>
                </View>
                <View style={leftIncrease("OTHER")}>
                  <Text style={styles.kioskTextStyle}>
                    {informations.itemOther}
                  </Text>
                </View>
              </>
            );
          })}
          {/* 합계 */}
          <Text style={styles.totalPrice}>
            {Math.round(sum).toLocaleString("ko-KR")}
          </Text>
          <Text style={styles.totalTax}>
            {Math.round(sum * "0.1").toLocaleString("ko-KR")}
          </Text>
          <Text style={styles.totalPriceATax}>
            {Math.round(sum * "0.1" + sum).toLocaleString("ko-KR")}
          </Text>
          {/* 기타사항 */}
          <View style={styles.sectionkioskAddMemo}>
            <Text style={styles.kioskAddMemo}>
              {userValue.orderRemarks && userValue.orderRemarks}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
